import React from "react";
import styled from "styled-components";

// Components
import ProjectBox from "../Elements/ProjectBox";

// Assets
import bj from "./../../assets/img/bj.png";
import bf from "./../../assets/img/bfa.png";
import se from "./../../assets/img/sn.png";
import ci from "./../../assets/img/ci.png";
import ml from "./../../assets/img/ml.png";
import tg from "./../../assets/img/tg.png";

import wallet from "../../assets/img/czion/Wallet.png";
import affiliate from "../../assets/img/czion/affiliate.png";
import Crypto from "../../assets/img/czion/CryptoExchange.png";
import Roadmap from "../../assets/img/czion/element1/Roadmap.png";
import bgaffiliate from "../../assets/img/czion/bg/bgAffiliate.png";
import bgDisponible from "../../assets/img/czion/bg/bgDisponible.png";
import {
  BackgroundImage,
  Box,
  Container,
  createStyles,
  Grid,
  Image,
  Text,
} from "@mantine/core";
import Slider from "react-slick";
import { EtapesComponent } from "../Elements/EtapesComponent";



const useStyles = createStyles((theme) => ({
  hiddenDesktop: {
    [theme.fn.largerThan(810)]: {
      display: "none",
    },
  },
  hiddenMobile: {
    [theme.fn.smallerThan(810)]: {
      display: "none",
    },
  },
}));

const data = [
  {
    pays: "Bénin",
    flag: bj,
  },
  {
    pays: "Burkina",
    flag: bf,
  },
  {
    pays: "Mali",
    flag: ml,
  },
  {
    pays: "Togo",
    flag: tg,
  },
  {
    pays: "Sénégal",
    flag: se,
  },
  {
    pays: "Côte d'ivoire",
    flag: ci,
  },
];

const dataEtape = [
  {
    titre: "Rejoignez notre grande",
    description:
      "Rejoignez notre grande communauté en vous inscrivant puis en confirmant votre inscription",
  },
  {
    titre: "Configurez votre profil",
    description:
      "Soumettez votre demande de vérification comme utilisateur de la plateforme. En passant votre KYC, vous nous permettez de vous venir en aide",
  },
  {
    titre: "Echangez avec nous",
    description:
      "Nous échangeons vos devises & cryptos à un tarif très compétitif sur le marché",
  },
];

const settings = {
  // centerMode: true,
  infinite: true,
  dots: true,
  speed: 100,
  slidesToShow: 6,
  slidesToScroll: 3,
  arrows: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
  ],
};

export default function Projects() {
  const { classes } = useStyles();

  return (
    <Wrapper id="projects">
       <Box style={{backgroundColor:'#e8e8ff'}}>
       {/* <Wrapper > */}
      <BackgroundImage src={bgDisponible}>
        <Container sx={{ zIndex: 99999 }}>
          {/* className="whiteBg" style={{ backgroundColor: " #e8e8ff" }} */}
          <div>
            <HeaderInfo>
              <Text ta={"center"}>cdndkckdjksjdnd</Text>
              <h1
                className="font40 extraBold  textCenter mt-5"
                style={{
                  color: "black",
                  marginTop: 80,
                  textTransform: "uppercase !important ",
                }}
              >
                NOUS SOMMES DISPONIBLES
              </h1>
            
              <p className="font13 textCenter">
                Echangez avec nous partout où que vous soyez dans les pays où nous sommes présents
              </p>
            </HeaderInfo>
            <Container className="slick-carousel">
              <Slider {...settings}>
                {data.map((item) => (
                  // <div className="col-xs-12 col-sm-4 col-md-2 col-lg-2">
                  <ProjectBox
                    img={item.flag}
                    title={item.pays}
                    text="Lorem ipsum "
                    action={() => alert("clicked")}
                  />
                  // </div>
                ))}

                {data.map((item) => (
                  // <div className="col-xs-12 col-sm-4 col-md-2 col-lg-2">
                  <ProjectBox
                    img={item.flag}
                    title={item.pays}
                    text="Lorem ipsum "
                    action={() => alert("clicked")}
                  />
                  // </div>
                ))}
              </Slider>
            </Container>
          </div>
        </Container>
      </BackgroundImage>

      {/* Section étape */}
      <BackgroundImage src={Roadmap}>
        <Box
          mt={100}
          py={10}
          style={{
            // backgroundColor: "#3b1c8c",
            color: "white",
          }}
        >
          <Text fz="xl" ta={"center"} mt={50} mb={20}>
            Commencer à échanger avec nous ?
          </Text>

          <Text fw={900} fz={40} ta={"center"} mb={100}>
            3 ÉTAPES
          </Text>

          <Box my={100} className={classes.hiddenMobile}>
            <Box my={15} className={"monStepperLanele"}>
              <Text pr={50} fz={"xl"} fw={900}>
                1
              </Text>
              <Text pr={50} fz={"xl"} fw={900}>
                2
              </Text>
              <Text pr={50} fz={"xl"} fw={900}>
                3
              </Text>
            </Box>
            <div id="monStepper">
              <span className="boule shadow"></span>
              <span className="boule shadow"></span>
              <span className="boule shadow"></span>
            </div>
            <div>
              <Box my={15} className={"monStepperLanele"}>
                {dataEtape.map((item, index) => (
                  <Box className={"monStepperContent"}>
                    <Text fz={"xl"} fw={500} ta={"justify"} className="text-justify ">
                      {item.titre}
                    </Text>
                    <div className="text-justify">
                      <Text ta={"justify"} className="text-inter text-sm font-semibold">{item.description}</Text>
                    </div>
                    
                  </Box>
                ))}
              </Box>
            </div>
          </Box>
          <div className="container-fluid">
            <Box my={100} className={classes.hiddenDesktop}>
              <EtapesComponent />
            </Box>
          </div>

        </Box>
      </BackgroundImage>

      {/* Section après section etape */}
      <BackgroundImage src={bgaffiliate} radius="sm">
        <Container>
          <div>
            <Advertising className="flexSpaceCenter" style={{ gap: 10 }}>
              <AddRight>
                <h2 className="font40 extraBold">Affiliez & Gagnez</h2>
                <Text fw={500} fz={"xl"} mb={20} className="text-justify">
                  Plus vous échangez & invitez, plus vous  gagnez 
                </Text>

                <Grid>

                  <Grid.Col md={12} >
                    {/* Premier box */}
                    <Box >
                      <Box
                        display={"flex"}
                        style={{ gap: 10, alignItems: "center" }}
                      >
                        <Image
                          src={affiliate}
                          alt={"logo"}
                          width={40}
                          height={80}
                          fit="contain"
                        />
                        <Text fw={900} fz={17}>
                          Affiliez
                        </Text>
                      </Box>
                      <div  width={"100px"} >
                        <Text className="text-justify">Gagnez un revenu passif</Text>
                      </div>
                      
                    </Box>
                  </Grid.Col>



                  <Grid.Col md={12}>
                    {/* Deuxième box */}
                    <Box >
                      <Box
                        display={"flex"}
                        style={{ gap: 10, alignItems: "center" }}
                      >
                        <Image
                          src={wallet}
                          alt={"logo"}
                          width={40}
                          height={80}
                          fit="contain"
                        />
                        <Text fw={900} fz={17}>
                          Gagnez
                        </Text>
                      </Box>
                      <div width={"50px"}>
                        <Text  className="text-justify">
                          En vous associant à CZION-FINANCE,vous gagnez
                          <br />
                           jusqu'à  40% 
                          des commissions 
                           de change de vos <br />
                            affiliés
                        </Text>
                      </div>
                      
                    </Box>
                  </Grid.Col>

                </Grid>

               
                <Box className={`my-8 mx-0 ${classes.hiddenMobile}`}>
                    <a href="https://exchange.czionfinance.com/" class="py-2 px-6 mx-0  font-semibold link hover:text-white"> Commencer maintenant &rarr; </a>
                </Box>
                <Box className={`my-8 mx-0 ${classes.hiddenDesktop}`}>
                    <a href="https://exchange.czionfinance.com/" class="py-2 px-1 mx-0  font-semibold link hover:text-white"> Commencer maintenant &rarr; </a>
                </Box>
              </AddRight>

              <AddLeft>
                <AddLeftInner>
                  <Box className="flexCenter" pl={30}>
                    <Image
                      className="radius8 lg:mt-12"
                      src={Crypto}
                      alt="add"
                      width={"100%"}
                      height={"100%"}
                      fit="contain"
                    />
                  </Box>
                </AddLeftInner>
              </AddLeft>
            </Advertising>
          </div>
        </Container>
      </BackgroundImage>
    {/* </Wrapper> */}
    </Box>
    </Wrapper>
   
   
  );
}

const Wrapper = styled.section`
  width: 100%;
  background-color: #e8e8ff;
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Advertising = styled.div`
  padding: 100px 0;
  margin: 100px 0 0 0 ;
  position: relative;
  @media (max-width: 1160px) {
    padding: 60px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;

const AddLeft = styled.div`
  position: relative;
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 1;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 50%;
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
  }
`;
const AddLeftInner = styled.div`
  width: 100%;
  position: absolute;
  top: -300px;
  left: 0;
  @media (max-width: 1190px) {
    top: -250px;
  }
  @media (max-width: 920px) {
    top: -200px;
  }
  @media (max-width: 860px) {
    order: 1;
    position: relative;
    top: -60px;
    left: 0;
  }
`;

