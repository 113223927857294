import React from "react";
import { useEffect } from "react";
import TopNavbar from "../components/Nav/TopNavbar";
import Pricing from "../components/Sections/Pricing";
import Footer from  "../components/Sections/Footer"; 
import { Box } from "@mantine/core";
import styled from "styled-components";
import Documentation from "../components/Sections/Documentation";


export default function Documentations() {
  const path = window.location.pathname;
  useEffect(() => {
    // "document.documentElement.scrollTo" is the magic for React Router Dom v6
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant", // Optional if you want to skip the scrolling animation
    });
  }, path);
    return (
        <>

        <TopNavbar />
        <Box
            mt={100}
            py={10}
            style={{ backgroundColor: "#3b1c8c", color: "white" }}
            >
            <div className="flex justify-center">
                <HeaderInfo>
                <h1 className="font20 extraBold text-uppercase">Documentation  </h1>
                <p className="font10">Acceuil - Documentation</p>
                </HeaderInfo>
            </div>
        </Box>
        <Documentation />
        <Pricing />
        <Footer />
        
        </>
    )
}

const HeaderInfo = styled.div`
  padding: 70px 0 30px 0;
  text-align: center;
  @media (max-width: 860px) {
    text-align: center;
  }
`;