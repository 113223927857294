import React from "react";
import styled from "styled-components";
import { Link } from "react-scroll";
import { Link as Lien } from "react-router-dom";
// Assets
import CloseIcon from "../../assets/svg/CloseIcon";
import WhiteIcon from "../../assets/img/czion/element1/side_white_icon.png"
import { useNavigate } from "react-router";

export default function Sidebar({ sidebarOpen, toggleSidebar }) {
  const navigate = useNavigate();
  return (
    <Wrapper className="animate darkBg" sidebarOpen={sidebarOpen}>
      <SidebarHeader className="flexSpaceCenter">
        <div className="flexNullCenter">
          <LogoWrapper>
              <Img className="radius8" src={WhiteIcon} alt="office" style={{zIndex: 9 , width : "200px"}} />
            </LogoWrapper>

          
        </div>
        <CloseBtn onClick={() => toggleSidebar(!sidebarOpen)} className="animate pointer text-white">
          <CloseIcon />
        </CloseBtn>
      </SidebarHeader>

      <UlStyle className="flexNullCenter flexColumn">
        <li className="semiBold font15 pointer">
          <Link
            onClick={() => navigate('/')}
            activeClass="active"
            className="whiteColor"
            style={{ padding: "10px 15px" }}
            to="home"
            spy={true}
            smooth={true}
            offset={-60}
          >
            Acceuil 
          </Link>
        </li>
        <li className="semiBold font15 pointer">
          <Link
            onClick={() =>navigate('/')}
            activeClass="active"
            className="whiteColor"
            style={{ padding: "10px 15px" }}
            to="services"
            spy={true}
            smooth={true}
            offset={-60}
          >
            Services
          </Link>
        </li>
        <li className="semiBold font15 pointer">
          <Lien
            activeClass="active"
            className="whiteColor"
            style={{ padding: "10px 15px" }}
            to="/about"
            spy={true}
            smooth={true}
            offset={-60}
          >
            A propos 
          </Lien>
        </li>
        
        {/* <li className="semiBold font15 pointer">
          <Link
            onClick={() => toggleSidebar(!sidebarOpen)}
            activeClass="active"
            className="whiteColor"
            style={{ padding: "10px 15px" }}
            to="pricing"
            spy={true}
            smooth={true}
            offset={-60}
          >
            
          </Link>
        </li> */}
        <li className="semiBold font15 pointer">
          <Lien
            // onClick={() => navigate('/contact')}
            activeClass="active"
            className="whiteColor"
            style={{ padding: "10px 15px" }}
            to="/contact"
            spy={true}
            smooth={true}
            offset={-60}
          >
            Contact
          </Lien>
        </li>

        <li className="semiBold font15 pointer">
          <Lien
            // onClick={() => navigate('/faq')}
            activeClass="active"
            className="whiteColor"
            style={{ padding: "10px 15px" }}
            to="/faq"
            spy={true}
            smooth={true}
            offset={-60}
          >
            Faqs
          </Lien>
        </li>
        <li className="semiBold font15 pointer">
          <a
           
            activeClass="active"
            className="whiteColor"
            style={{ padding: "10px 15px" }}
            href="https://exchange.czionfinance.com"
            spy={true}
            smooth={true}
            offset={-60}
            >
            Connexion
          </a>
        </li>
      </UlStyle>
      {/* <UlStyle className="flexSpaceCenter">
        <li className="semiBold font15 pointer">
          <Link to="/" style={{ padding: "10px 30px 10px 0" }} className="whiteColor">
            Connexion
          </Link>
        </li>
        <li className="semiBold font15 pointer flexCenter">
          <Link to="/" className="radius8 lightBg" style={{ padding: "10px 15px" }}>
            Get Started
          </Link>
        </li>
      </UlStyle> */}
    </Wrapper>
  );
}

const Wrapper = styled.nav`
  width: 300px;
  height: 100vh;
  position: fixed;
  top: 0;
  padding: 0 30px;
  right: ${(props) => (props.sidebarOpen ? "0px" : "-400px")};
  z-index: 9999;
  @media (max-width: 300px) {
    width: 100%;
  }
`;
const SidebarHeader = styled.div`
  padding: 20px 0;
`;
const CloseBtn = styled.button`
  border: 0px;
  outline: none;
  background-color: transparent;
  padding: 10px;
  color: white; 
`;
const UlStyle = styled.ul`
  padding: 40px;
  li {
    margin: 20px 0;
  }
`;
const LogoWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  position: absolute;
  z-index: 9;
  @media (max-width: 960px) {
    width: 50%;
    justify-content: flex-start;
    display : flex; 
  }
`;

const Img = styled.img`
  @media (max-width: 560px) {
    width: 30%;
    height: auto;
  }
`;
