import React, { useEffect } from "react";
import styled from "styled-components";
import {
  createStyles,
  Image,
  Accordion,
  Grid,
  Col,
  Container,
  Box,
  Text,
} from "@mantine/core";
import Footer from "../components/Sections/Footer";
import TopNavbar from "../components/Nav/TopNavbar";
import { IconPlus } from "@tabler/icons";
import Pricing from "../components/Sections/Pricing";
import faqImage from "./../assets/img/quizfaq.png";


const useStyles = createStyles((theme) => ({
  wrapper: {
    paddingTop: theme.spacing.xl * 2,
    paddingBottom: theme.spacing.xl * 2,
  },

  title: {
    marginBottom: theme.spacing.md,
    paddingLeft: theme.spacing.md,
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
  },

  item: {
    fontSize: theme.fontSizes.sm,
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[1]
        : theme.colors.gray[7],
  },
  BoxAccordion: {
    marginTop: 150,
    [theme.fn.smallerThan(810)]: {
      marginTop: 0,
    },
  },
}));

const faqs = [
  {
    title: "Qui sommes  nous ? ",
    placeholder:
      "CZION FINANCE est un produit de CZION SARL qui est dûement enregistré auprès de l'Agence de Promotion des Investissements et des Exportations (APIEx) au Bénin.",
    value: "who-are-us",
  },
  {
    title: "Comment échanger avec nous ? ",
    placeholder:
      "Il suffit de choisir la cryptomonnaie (ou la devise) que vous souhaitez acheter (ou vendre), de recharger votre portefeuille de votre monnaie locale en utilisant votre méthode de paiement préférée, d'entrer la quantité et de confirmer la transaction en envoyant le montant équivalent ",
    value: "how-to-exchange",
  },
  {
    title: "Quelles sont nos heures d'ouvertures ? ",
    placeholder:
      "Notre plateforme est ouverte 24heures/24 7jours/7. Néanmoins après 00h les transactions peuvent prendre un peu plus de temps a être traitée par notre équipe et certains moyens de paiement peuvent être indisponible ",
    value: "hours",
  },
  {
    title: "D'autres questions ?",
    placeholder:
      "N'hésitez pas à nous contacter via notre chatbot pour que nous puissions vous aider à eclaicir vos préoccupations ",
    value: "questions",
  },
];

function Faq(props) {
  const { classes } = useStyles();
  const path = window.location.pathname;
  useEffect(() => {
    // "document.documentElement.scrollTo" is the magic for React Router Dom v6
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant", // Optional if you want to skip the scrolling animation
    });
  }, path);
  const width  = window.innerWidth ; 
  const iPad  = (() => {
     return width > 800 && width < 860 
  })
  return (
    <div className={classes.wrapper}>
      <TopNavbar />

      <Box
        mt={54}
        py={30}
        style={{ backgroundColor: "#3b1c8c", color: "white" }}
      >
        <div className="flex justify-center">
          <HeaderInfo>
            <h1 className="font20 extraBold text-uppercase">Faqs </h1>
            <p className="font10">Acceuil - Faqs</p>
          </HeaderInfo>
        </div>
      </Box>

      <Container size="lg" mt={60} mb={100}>
        <Grid id="faq-grid" gutter={50}>
          <Col span={12} md={6}>
            <Text tt={"uppercase"} fz={28} fw={900}>
              Foire <br /> aux questions
            </Text>
            <Image src={faqImage} alt="Frequently Asked Questions" mt={50} width={ iPad ? "70%"  : "" } />
          </Col>
          <Col span={12} md={6}>
            {/* <Title order={2} align="left" className={classes.title}>
                Frequently Asked Questions

              </Title> */}
            <Box className={classes.BoxAccordion}>
              <Accordion
                chevronPosition="right"
                defaultValue="who-are-us"
                variant="separated"
                chevron={<IconPlus size={30} fw={900} />}
                styles={{
                  chevron: {
                    "&[data-rotate]": {
                      transform: "rotate(45deg)",
                    },
                  },
                }}
              >
                {faqs.map((faq) => (
                  <Accordion.Item className={classes.item} value={faq.value}>
                    <Accordion.Control>{faq.title}</Accordion.Control>
                    <Accordion.Panel>{faq.placeholder}</Accordion.Panel>
                  </Accordion.Item>
                ))}
              </Accordion>
            </Box>
          </Col>
        </Grid>
      </Container>
      <Pricing />
      <Footer />
    </div>
  );
}

export default Faq;

const HeaderInfo = styled.div`
  padding: 40px 0 20px 0;
  text-align: center;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
