import { Box, createStyles } from "@mantine/core";
import React from "react";
import Slider from "react-slick";
import abdias from "../../assets/img/czion/abdias.PNG";
import isaac from "../../assets/img/czion/isaac.jpeg";
import omonlara from "../../assets/img/czion/omolara.png";

const useStyles = createStyles((theme) => ({
  hiddenDesktop: {
    [theme.fn.largerThan("md")]: {
      display: "none",
    },
  },
  hiddenMobile: {
    [theme.fn.smallerThan("md")]: {
      display: "none",
    },
  },
}));

function EquipeCard(props) {
  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 5000,
    autoplaySpeed: 5000,
    pauseOnHover: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    
    // autoplay: true,
    // autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const teams = [
    {
      profile: isaac,
      name: " Isaac COFFI",
      title: "CEO & Founder ",
      twitter: "https://twitter.com/CJesugnon",
      linkedin: "https://www.linkedin.com/in/jesugnon-coffi-a56694185/",
    },
    {
      profile: omonlara,
      name: "Miskiyath TOLACHE",
      title: "  Customer Success  Manager ",

      twitter: "#",
      linkedin:
        "https://www.linkedin.com/in/miskiyath-omolara-tolache-4781b320a/",
    },
    {
      profile: abdias,
      name: " Olaniran AFOUDA",
      title: " Lead Developer & Project Manager  ",
      facebook: "#",
      twitter: "https://twitter.com/heyolaniran",
      linkedin: "https://www.linkedin.com/in/abdias-afouda-06461022a/",
    },
  ];

  const { classes } = useStyles();
  return (
    <Box className="container my-24 px-6 mx-auto">
      {/* Affichage Web */}
      <Box
        className={`${classes.hiddenMobile} mb-32 text-gray-800 text-center`}
      >
        <Box className="grid gap-x-6 lg:gap-x-12 md:grid-cols-3 ">
          {teams.map((team) => (
            <Box className="mb-24 md:mb-0 mx-2">
              <Box className="rounded-lg shadow-md hover:shadow-xl duration-500 h-full block bg-gray-200">
                <Box className="flex justify-center">
                  <Box className="flex justify-center -mt-12">
                    <img
                      src={team.profile}
                      className="rounded-full mx-auto shadow-lg"
                      alt=""
                      width={"110px"}
                    />
                  </Box>
                </Box>
                <Box className="p-6">
                  <h5 className="text-lg font-bold mb-4">{team.name}</h5>
                  <p className="mb-6">{team.title}</p>
                  <ul className="list-inside flex mx-auto justify-center">
                    <a href={team.twitter} className="px-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                        className="w-4 h-4 text-blue-800"
                      >
                        <path
                          fill="currentColor"
                          d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"
                        />
                      </svg>
                    </a>
                    <a href={team.linkedin} className="px-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                        className="w-4 h-4 text-blue-800"
                      >
                        <path
                          fill="currentColor"
                          d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"
                        />
                      </svg>
                    </a>
                  </ul>
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>

      {/* Affichage mobile */}
      <Box
        className={`${classes.hiddenDesktop} mb-32 text-gray-800 text-center`}
      >
        <Slider {...settings}>
          {teams.map((team) => (
            <Box sx={{marginTop:60}}>
              <Box className="mb-24 md:mb-0 mx-2">
                <Box className="rounded-lg shadow-md hover:shadow-xl duration-500 h-full block bg-gray-200">
                  <Box className="flex justify-center">
                    <Box className="flex justify-center -mt-12">
                      <img
                        src={team.profile}
                        className="rounded-full mx-auto shadow-lg"
                        alt=""
                        width={"110px"}
                      />
                    </Box>
                  </Box>
                  <Box className="p-6">
                    <h5 className="text-lg font-bold mb-4">{team.name}</h5>
                    <p className="mb-6">{team.title}</p>
                    <ul className="list-inside flex mx-auto justify-center">
                      <a href={team.twitter} className="px-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                          className="w-4 h-4 text-blue-800"
                        >
                          <path
                            fill="currentColor"
                            d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"
                          />
                        </svg>
                      </a>
                      <a href={team.linkedin} className="px-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 448 512"
                          className="w-4 h-4 text-blue-800"
                        >
                          <path
                            fill="currentColor"
                            d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"
                          />
                        </svg>
                      </a>
                    </ul>
                  </Box>
                </Box>
              </Box>
            </Box>
          ))}
        </Slider>
      </Box>
    </Box>
  );
}

export default EquipeCard;
