import React from "react";
import {
  createStyles,
  Image,
  Accordion,
  Grid,
  Col,
  Container,
  Text,
  Box,
} from "@mantine/core";
import { IconPlus } from "@tabler/icons";
import mentionImg from "./../../assets/img/mention.svg";
import bj from "./../../assets/img/bj.png";
import TopNavbar from "../Nav/TopNavbar";

const useStyles = createStyles((theme) => ({
  wrapper: {
    paddingTop: theme.spacing.xl * 2,
    paddingBottom: theme.spacing.xl * 2,
  },

  title: {
    marginBottom: theme.spacing.md,
    paddingLeft: theme.spacing.md,
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
  },

  item: {
    fontSize: theme.fontSizes.sm,
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[1]
        : theme.colors.gray[7],
  },
  BoxAccordion:{
    marginTop:100,
    [theme.fn.smallerThan("md")]: {
        marginTop:0,
      },
  }
}));



function Mention(props) {
  const { classes } = useStyles();
  return (
    <div className={classes.wrapper}>
      <TopNavbar />

      <Container size="lg" mt={60} mb={100}>
        <div className="flex justify-center text-upper extraBold font25">
            MENTIONS LEGALES 
        </div>
        <Grid id="faq-grid" gutter={50}>
          <Col span={12} md={6}>
            
            <Image src={mentionImg} alt="Mentions légales" mt={100} width={375} />
          </Col>
          <Col span={12} md={6}>
          <Box className={classes.BoxAccordion}>
            <Accordion
              chevronPosition="right"
              defaultValue="who-are-us"
              variant="separated"
              chevron={<IconPlus size={30} fw={900} />}
              styles={{
                chevron: {
                  "&[data-rotate]": {
                    transform: "rotate(45deg)",
                  },
                },
              }}
            >
              {/* Liste des accordions */}

              <Accordion.Item className={classes.item} value={"1"}>
                <Accordion.Control>
                  <Text fw={600} fz={"lg"} tt={"title"}>
                    1. Le Site Internet
                  </Text>
                </Accordion.Control>
                <Accordion.Panel>
                  <Text fz={"md"}>
                    Le Site internet{" "}
                    <a
                      href="http:// czionfinance.com"
                      style={{ color: "#5ca2dc" }}
                    >
                      czionfinance.com
                    </a>{" "}
                    appartient à CZION SARL, une société à responsabilité
                    limitée (SARL) enregistrée au RCCM de la République du Bénin
                    sous le numéro RB/PNO/22 B 3918 et dont le siège social est
                    à Porto-Novo (Benin{" "}
                    <span>
                      <img
                        src={bj}
                        height={20}
                        width={25}
                        className={"benin"}
                        alt={"benin"}
                      />
                    </span>{" "}
                    ) Ilot : 62, Quartier : Dowa . <br />
                    <br />
                    Le Directeur de la publication est{" "}
                    <strong>Isaac COFFI</strong>
                  </Text>
                </Accordion.Panel>
              </Accordion.Item>

              {/* Deuxième accordion */}
              <Accordion.Item className={classes.item} value={"2"}>
                <Accordion.Control>
                  <Text fw={600} fz={"lg"} tt={"title"}>
                    2. Déclaration à l’APDP
                  </Text>
                </Accordion.Control>
                <Accordion.Panel>
                  <Text fz={"md"}>
                    Le Site internet a fait l'objet d'une déclaration à l’APDP
                    le , conformément à la loi n° 2017-20 du 20 avril 2018
                    portant code du numérique en République du Bénin (Livre 5ème
                    relatif à la protection des données personnelles et de la
                    vie privée).
                  </Text>
                </Accordion.Panel>
              </Accordion.Item>

              {/* Troisième accordion */}
              <Accordion.Item className={classes.item} value={"3"}>
                <Accordion.Control>
                  <Text fw={600} fz={"lg"} tt={"title"}>
                    3. Contact commercial
                  </Text>
                </Accordion.Control>
                <Accordion.Panel>
                  <Text fz={"md"}>
                    <a
                      href="mailto:info@czionfinance.com"
                      style={{ color: "#5ca2dc" }}
                    >
                      info@czionfinance.com
                    </a>
                  </Text>
                </Accordion.Panel>
              </Accordion.Item>

              {/* Quatrième accordion */}
              <Accordion.Item className={classes.item} value={"4"}>
                <Accordion.Control>
                  <Text fw={600} fz={"lg"} tt={"title"}>
                    4. Hébergement
                  </Text>
                </Accordion.Control>
                <Accordion.Panel>
                  <Text fz={"md"}>
                    Le site et les services web{" "}
                    <a
                      href="https://czionfinance.com"
                      style={{ color: "#5ca2dc" }}
                    >
                      czionfinance.com
                    </a>{" "}
                    sont hébergés par la société : LIGNE WEB SERVICES (LWS)
                    situé en France
                    <br /> <br />
                    Le site web :
                    <a href="https://lws.fr" style={{ color: "#5ca2dc" }}>
                      https://lws.fr
                    </a>
                    <br /> <br />
                    10 rue Penthièvre, 75008 PARIS
                  </Text>
                </Accordion.Panel>
              </Accordion.Item>
            </Accordion>

            </Box>
          </Col>
        </Grid>
      </Container>
    </div>
  );
}

export default Mention;
