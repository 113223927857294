import React from "react";
// Sections
import TopNavbar from "../components/Nav/TopNavbar";
import Contact from "../components/Sections/Contact";
import Footer from "../components/Sections/Footer"
import Pricing from "../components/Sections/Pricing";
import HeaderContact from "../components/Sections/HeaderContact";
export default function Contacts() {
  return (
    <>
      <TopNavbar />
      <HeaderContact />
      <Contact />
      <Pricing />
      <Footer />
    </>
  );
}


