import { Box, Card, createStyles, Text } from "@mantine/core";
import Slider from "react-slick";

const useStyles = createStyles((theme) => ({
  root: {
    display: "flex",
    backgroundColor: "white",
    // backgroundImage: `linear-gradient(-60deg, ${
    //   theme.colors[theme.primaryColor][4]
    // } 0%, ${theme.colors[theme.primaryColor][7]} 100%)`,
    padding: theme.spacing.xl * 1.5,
    borderRadius: theme.radius.md,

    [theme.fn.smallerThan("sm")]: {
      flexDirection: "column",
    },
  },

  title: {
    color: theme.white,
    textTransform: "uppercase",
    fontWeight: 700,
    fontSize: theme.fontSizes.sm,
  },

  count: {
    color: "#bb2566",
    fontSize: 32,
    lineHeight: 1,
    fontWeight: 700,
    marginBottom: theme.spacing.md,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
  },

  description: {
    color: "black",
    fontSize: theme.fontSizes.sm,
    marginTop: 5,
  },

  stat: {
    flex: 1,
    backgroundColor: "white",

    "& + &": {
      paddingLeft: theme.spacing.xl,
      marginLeft: theme.spacing.xl,
      borderLeft: `1px solid ${theme.colors[theme.primaryColor][3]}`,

      [theme.fn.smallerThan("sm")]: {
        paddingLeft: 0,
        marginLeft: 0,
        borderLeft: 0,
        paddingTop: theme.spacing.xl,
        marginTop: theme.spacing.xl,
        borderTop: `1px solid ${theme.colors[theme.primaryColor][3]}`,
      },
    },
  },
  hiddenDesktop: {
    [theme.fn.largerThan("md")]: {
      display: "none",
    },
  },
  hiddenMobile: {
    [theme.fn.smallerThan("md")]: {
      display: "none",
    },
  },
}));

const data = [
  {
    title: "Page views",
    stats: "200M+",
    description: "Membres dans nos communautés",
  },
  {
    title: "New users",
    stats: "100%",
    description: "Clients satisfaits",
  },
  {
    title: "Completed orders",
    stats: "24/7",
    description: "Places de marché",
  },
];

export function StatsGroup() {
  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 5000,
    autoplaySpeed: 5000,
    pauseOnHover: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    // autoplay: true,
    // autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const { classes } = useStyles();
  const stats = data.map((stat) => (
    <div key={stat.title} className={classes.stat}>
      <Text className={classes.count} ta={"center"}>
        {stat.stats}
      </Text>
      <Text className={classes.description} ta={"center"}>
        {stat.description}
      </Text>
    </div>
  ));

  const stats2 = data.map((stat) => (
    <Card shadow={"sm"} radius={12} p={0} py={10} my={50}>
      <div key={stat.title} className={classes.stat}>
        <Text className={classes.count} ta={"center"}>
          {stat.stats}
        </Text>
        <Text className={classes.description} ta={"center"}>
          {stat.description}
        </Text>
      </div>
    </Card>
  ));
  return (
    <>
      <Box className={classes.hiddenMobile}>
        <Card shadow={"xl"} radius={12} p={0} py={10} my={50}>
          <div className={classes.root}>{stats}</div>
        </Card>
      </Box>
      <Box className={classes.hiddenDesktop}>
        <Slider {...settings}>{stats2}</Slider>
      </Box>
    </>
  );
}
