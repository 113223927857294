import {
  Box,
  Button,
  Container,
  createStyles,
  Text,
  TextInput,
} from "@mantine/core";
import React, { useState } from "react";
import styled from "styled-components";
// Components


const useStyles = createStyles((theme) => ({
  champ:{
    [theme.fn.smallerThan("md")]: {
      width: "100%",
      display:'flex',
      margin: "5px 0",
      
    },
  },
  contact: {
    width: "40%",
    marginInline: "auto",
    [theme.fn.smallerThan(810)]: {
      fontSize:'10 !iportant',
      width: "100%",
      display:'block',
      margin: "5px",
      justifyContent:'center',
      alignContent:'center',
      textAlign:'center',
    },
  },
  hiddenDesktop: {
    [theme.fn.largerThan(810)]: {
      display: "none",
    },
  },
  hiddenMobile: {
    [theme.fn.smallerThan(810)]: {
      display: "none",
    },
  },
}));

export default function Pricing() {
  const { classes } = useStyles();
  const [mail , SetMail] = useState('') ; 
  const [isPending , SetIsPending] = useState(false); 
  const handleSubmit = (e) => {
    e.preventDefault() ; 
    const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    
    if(regex.test(mail))
    {
      console.log("true mail  : " + mail )
      SetIsPending(true) ; 
      
      /*fetch("http://localhost:8080/index.php", {
        method : 'POST', 
        headers : {'X-Requested-With': 'xmlhttprequest'}, 
        body : 'email ' + mail 
      })
      .then( (response) => {
        console.log(response)
       
      })*/
    } else 
      console.log(" wrong mail format "+ mail)
  }
  return (
    <Wrapper id="pricing">
      <Container>
        <div className="container">
          <HeaderInfo>
            <Text ta={"center"} fw={6000}  fz={30} c={""}>
              Obtenez des analyses, des nouvelles et des mises à jour sur les
              crypto-monnaies
            </Text>
          </HeaderInfo>
          <Box className={classes.contact}>
            <Text fw={900} fz={25} ta={"center"}>
              NEWSLETTER
            </Text>
            <TextInput
              size={"lg"}
              radius="xl"
              type="email"
              required
              value={mail}
              onChange={(e) => {SetMail(e.target.value)}}
              variant="filled"
              placeholder="Votre Email"
              className={`contactInput ${classes.champ}`}
              rightSection={

                <Button
                 className="hover:bg-blue-800 news"
                  size={"lg"}
                  sx={{
                    borderRadius: "0px 32px 32px 0px ",
                    backgroundColor: "#fe682c",
                  }}
                  onClick={ handleSubmit }
                >
                  { !isPending ? "Souscrire" : "Souscription..."}
                </Button>
              }
            />
          </Box>
        </div>
      </Container>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding: 50px 0;
  background-color: #f2f2ff;
`;
const HeaderInfo = styled.div`
  margin-bottom: 50px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;


