import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "react-scroll";
// Components
import Sidebar from "../Nav/Sidebar";
import Backdrop from "../Elements/Backdrop";
// Assets
import WhiteIcon from "../../assets/img/czion/element1/nav_white_icon.png"
import logoCZION from "./../../assets/img/czion/Czionfinance.png";
import BurgerIcon from "../../assets/svg/BurgerIcon";
import { useNavigate } from "react-router";
import { Box } from "@mantine/core";


export default function TopNavbar() {
  const [y, setY] = useState(window.scrollY);
  const [sidebarOpen, toggleSidebar] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => setY(window.scrollY));
    return () => {
      window.removeEventListener("scroll", () => setY(window.scrollY));
    };
  }, [y]);

  const navigate = useNavigate();
  const path = window.location.pathname;
  const width  = window.innerWidth ; 
  console.log(width); 
  const iPad =  width > 800 && width < 860  

  return (
    <>
      <Sidebar sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
      {sidebarOpen && <Backdrop toggleSidebar={toggleSidebar} />}
      <Wrapper
        className="flexCenter animate "
        style={
          iPad  ? {
            background: "#1c1c6c", 
            height: "80px",
            color: "black !important",
          }  : 
            y > 100
            ? {
                height: "80px",
                background: "#1c1c6c",
                color: "black !important",
                
              }
            : { height: "80px"
           } 
          
          
        }
      >
        <NavInner className="container flexSpaceCenterAAA">
          <Link className="pointer flexNullStart" to="home" smooth={true}>
            <LogoWrapper>
              <Img
                className="radius8 container w-25 h-25 continer-fluid mb-4"
                src={y < 100 ? logoCZION  : WhiteIcon}
                alt="office"
              />
            </LogoWrapper>
          </Link>
          <BurderWrapper
            className="pointer "
            onClick={() => toggleSidebar(!sidebarOpen)}
          >
            <BurgerIcon color={(path !== "/" && y < 100) ?'#1C1C6C':'white'} />
          </BurderWrapper>
          <Box display={"flex"} sx={{ gap: 45 }}>
            <UlWrapper className="flexNullCenter">
              <li className="semiBold font15 pointer ">
                <Link
                  activeClass="active"
                  style={
                    y > 100
                      ? { color: "#fff", padding: "10px 15px" }
                      : path !== "/"
                      ? { color: "black ", padding: "10px 15px" }
                      : { color: "#f5d6fc ", padding: "10px 15px" }
                  }
                  onClick={() => navigate("/")}
                  to="home"
                  spy={true}
                  smooth={true}
                  offset={-80}
                >
                  Accueil
                </Link>
              </li>
              <li className="semiBold font15 pointer lien">
                <Link
                  activeClass="active"
                  style={
                    y > 100
                      ? { color: "#fff", padding: "10px 15px" }
                      : path !== "/"
                      ? { color: "black ", padding: "10px 15px" }
                      : { color: "#f5d6fc ", padding: "10px 15px" }
                  }
                  onClick={() => navigate("/")}
                  to="services"
                  spy={true}
                  smooth={true}
                  offset={-80}
                >
                  Services
                </Link>
              </li>
              <li
                className="semiBold font15 pointer lien"
                style={{ width: "max-content" }}
              >
                <Link
                  activeClass="active"
                  style={
                    y > 100
                      ? { color: "#fff", padding: "10px 15px" }
                      : path !== "/"
                      ? { color: "black ", padding: "10px 15px" }
                      : { color: "#f5d6fc ", padding: "10px 15px" }
                  }
                  onClick={() => navigate("/about")}
                  to="/about"
                  spy={true}
                  smooth={true}
                  offset={-80}
                >
                  A propos
                </Link>
              </li>
              <li className="semiBold font15 pointer lien">
                <Link
                  activeClass="active"
                  style={
                    y > 100
                      ? { color: "#fff", padding: "10px 15px" }
                      : path !== "/"
                      ? { color: "black ", padding: "10px 15px" }
                      : { color: "#f5d6fc ", padding: "10px 15px" }
                  }
                  onClick={() => navigate("/contact")}
                  spy={true}
                  smooth={true}
                  offset={-80}
                >
                  Contact
                </Link>
              </li>
              <li className="semiBold font15 pointer lien">
                <Link
                  activeClass="active"
                  style={
                    y > 100
                      ? { color: "#fff", padding: "10px 15px" }
                      : path !== "/"
                      ? { color: "black ", padding: "10px 15px" }
                      : { color: "#f5d6fc ", padding: "10px 15px" }
                  }
                  onClick={() => navigate("/faq")}
                  spy={true}
                  smooth={true}
                  offset={-80}
                >
                  Faqs
                </Link>
              </li>
            </UlWrapper>
            <UlWrapperRight className="">
              <a
                className="font20 semiBold text-[#1c1c2c]"
                href="https://exchange.czionfinance.com/"
                style={
                  y > 100
                    ? {
                        fontSize: "15px",
                        backgroundColor: "white",
                        color: "#1C1C6C",
                        borderRadius: "32px",
                        padding: "10px 25px",
                        marginRight: "40px",
                      }
                    : path !== "/"
                    ? {
                      fontSize: "15px",
                      backgroundColor: "white",
                      color: "blue",
                      borderRadius: "32px",
                      padding: "10px 25px",
                      marginRight: "40px",
                      border: "1px solid blue",
                      }
                    : {
                        fontSize: "15px",
                        backgroundColor: "white",
                        color: "#1C1C6C",
                        borderRadius: "32px",
                        padding: "10px 25px",
                        marginRight: "40px",
                      }
                }
              >
                connexion
              </a>
            </UlWrapperRight>
          </Box>
        </NavInner>
      </Wrapper>
    </>
  );
}

const Wrapper = styled.nav`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;

  @media screen and (max-width : 960px) and (min-width : 860px)
  {
     backgound : #1c1c6c ; 
  }
`;
const NavInner = styled.div`
  position: relative;
  height: 100%;
`;
const BurderWrapper = styled.button`
  outline: none;
  border: 0px;
  background-color: transparent;
  height: 100%;
  padding: 0 15px;
  display: none;
  @media (max-width: 760px) {
    display: block;
  }
`;
const UlWrapper = styled.ul`
  display: flex;
  @media (max-width: 760px) {
    display: none;
  }
`;
const UlWrapperRight = styled.ul`
  @media (max-width: 760px) {
    display: none;
  }
`;

const LogoWrapper = styled.div`
  display: flex;
  padding-left: 10px;
  justify-content: flex-start;
  position: relative;
  z-index: 9;
  @media screen and (max-width: 960px) and (min-width : 860px){
    width: 50%;
    justify-content: start;
  }

@media screen and (max-width : 800px)
{
  width: 50%;
  justify-content: start;
}

`;

const Img = styled.img`
  z-index: 4;
  width: 100px;
  height: 10em;
  margin: 30px 0px 0px 20px;
  @media (max-width: 560px) {
    width: 20%;
    height: auto;
  }
`;
