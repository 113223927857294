import React from "react";
import {
  Box,
  Container,
  Grid,
  Text,
} from "@mantine/core";
import mission from "../../assets/img/czion/mission.png";
export default function Mission() {
  return (
    <Container size="lg" mt={60} mb={100} py={30}>
      
     
      <Box className="justify-center flex">
        <Text fw={900} fz={35} c={'white'}>
          Notre Mission
        </Text>
      </Box>

      <Grid>
        <Grid.Col md={6} order={1} orderMd={2}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <img src={mission} alt="" />
          </Box>
        </Grid.Col>
        <Grid.Col
          md={6}
          order={2}
          orderMd={1}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "justify",
          }}
        >
          <Container size={"lg"}>
            <Box sx={{ height: "100%" }}>
              <Text fz={"md"} c={'white'}>
                Nous oeuvrons à vous fournir des services de changes inter &
                intrarégionnaux, vous accompagner dans l'internationalisation de
                vos transferts d'argent à moindre coût au travers des différents
                services que nous vous proposons{" "}
              </Text>
            </Box>
          </Container>
        </Grid.Col>
      </Grid>
    </Container>
  );
}



