import React from "react";
import Slider from "react-slick";
// Assets
import emoney from "../../assets/img/czion/element1/e-money.png";
import free from "../../assets/img/czion/element1/free.png";
import moov from "../../assets/img/czion/element1/moov.png";
import mtn from "../../assets/img/czion/element1/mtn.png";

import visa from "../../assets/img/czion/element1/visa.png";
import wave from "../../assets/img/czion/element1/wave.png";
import wizall from "../../assets/img/czion/element1/wizall.png";
import mastercard from "../../assets/img/czion/element1/mastercard.png";

import tmoney from "../../assets/img/czion/element1/tmoney.png";
import om from "../../assets/img/czion/element1/om.png";
import { Avatar, Box, Container, createStyles, Grid } from "@mantine/core";

const data = [
  {
    image: visa,
    alt: "visa",
  },
  {
    image: mastercard,
    alt: "mastercard",
  },
  {
    image: wave,
    alt: "wave",
  },
  {
    image: wizall,
    alt: "wizall",
  },
  {
    image: emoney,
    alt: "emoney",
  },
  {
    image: free,
    alt: "free",
  },
  {
    image: om,
    alt: "om",
  },
  {
    image: tmoney,
    alt: "tmoney",
  },
  {
    image: moov,
    alt: "moov",
  },
  {
    image: mtn,
    alt: "tmoney",
  },
];

const useStyles = createStyles((theme) => ({
  hiddenDesktop: {
    [theme.fn.largerThan("md")]: {
      display: "none",
    },
  },
  hiddenMobile: {
    [theme.fn.smallerThan("md")]: {
      display: "none",
    },
  },
}));

export default function ClientSlider() {
  const { classes } = useStyles();

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 2,
    arrows: true,
    autoplay: true,
    speed: 5000,
    autoplaySpeed: 5000,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 3,
        },
      },
    ],
  };

  return (
    <div className="p-4">
      <Container size={'sm'} className={classes.hiddenDesktop} sx={{padding:'10px 10px',width:'80vw'}}>
        <Slider {...settings}>
          {data.map((item, indexe) => (
            <Avatar src={item.image}  size={100} />
          ))}
        </Slider>
      </Container>

      <Box className={classes.hiddenMobile}>
        <Grid>
          {data.map((item, indexe) => (
            <Grid.Col md={2.4}>
              <Avatar src={item.image} mx={"auto"} size={100} />
            </Grid.Col>
          ))}
        </Grid>
      </Box>
    </div>
  );
}


