import React from "react";
import { BrowserRouter, Route, Router, Routes } from "react-router-dom";
import Contacts from "./screens/Contacts.jsx";
import Faq from "./screens/Faq.jsx";
// Screens
import Landing from "./screens/Landing.jsx";
import Mentions from "./screens/Mentions.jsx";
import Politique from "./screens/Politique.jsx";
import Documentations from "./screens/Documentations.jsx";
import AboutScreen from "./screens/AboutScreen.jsx";

export default function App() {
  return (
    <>
      <>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Khula:wght@400;600;800&display=swap"
          rel="stylesheet"
        />
        <link
          href="https://unpkg.com/tailwindcss@1.4.6/dist/tailwind.min.css"
          rel="stylesheet"
        />
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.1/css/all.min.css" integrity="sha512-MV7K8+y+gLIBoVD59lQIYicR65iaqukzvf/nwasF0nqhPay5w/9lJmVM2hMDcnK1OnMGCdVK+iQrJ7lzPJQd1w==" crossorigin="anonymous" referrerpolicy="no-referrer" />
      <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.6.3/jquery.min.js" integrity="sha512-STof4xm1wgkfm7heWqFJVn58Hm3EtS31XFaagaa8VMReCXAkQnJZ+jEy8PCC/iT18dFy95WcExNHFTqLyp72eQ==" crossorigin="anonymous" referrerpolicy="no-referrer"></script>
      <script src="./https.js">
      </script>

      </>

      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/contact" element={<Contacts />} />
          <Route path="/faq" element={<Faq />} />
          <Route path='/mention' element={<Mentions /> } />
          <Route path='/politique' element={<Politique /> } />
          <Route path='/about' element={<AboutScreen /> } />
          <Route path='/documentation' element={<Documentations /> } />
        </Routes>
      </BrowserRouter>


    </>
  );
}
