import { Container } from "@mantine/core";
import React from "react";
import Slider from "react-slick";
import styled from "styled-components";
import testimoniLogo from "./../../assets/img/czion/testimonie.png";
// Components
import TestimonialBox from "../Elements/TestimonialBox";

const data = [
  {
    image: testimoniLogo,
    nom: "Diane A.",
    note: 5,
    message:
      "CZION FINANCE offre aux utilisateurs une expérience utilisateur et une interface exceptionnelles. Leurs tarifs sont également les meilleurs du marché !",
  },
  {
    image: testimoniLogo,
    nom: "Marc H.",
    note: 4.5,
    message:
      "CZION FINANCE a un excellent support ! Ma requête a été résolue en moins d'une heure. J'aime la simplicité de leur plateforme et les incitations proposées par le biais du programme d'affiliation !",
  },
  {
    image: testimoniLogo,
    nom: "Jean-Yves D.",
    note: 5,
    message:
      "CZION FINANCE offre aux utilisateurs une expérience utilisateur et une interface exceptionnelles. Leurs tarifs sont également les meilleurs du marché !",
  },
  {
    image: "",
    nom: "Gildas ",
    note: 5,
    message:
      "CZION FINANCE offre aux utilisateurs une expérience utilisateur et une interface exceptionnelles. Leurs tarifs sont également les meilleurs du marché !",
  },
];
export default function TestimonialSlider() {
  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 5000,
    autoplaySpeed: 5000,
    pauseOnHover: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    // autoplay: true,
    // autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  /*const next = ()=>{

    const elem  = (document.querySelector('.slick-next'));
    
    console.log(elem.click()) ; 
    
  }*/

 /* const prev = ()=>{
    console.log("prev");
    console.log(document.querySelector('.slick-prev'))
    // document.querySelector('.slick-prev').setAttribute('id',,);
  }*/

// useEffect(() => {
//  $(".ligne").appendArrows()

// }, [])

  return (
    <Container py={8} size={'lg'}>
      <Slider {...settings}>
        {data.map((item) => (
          <LogoWrapper className="flexCenter">
            <TestimonialBox
              image={item.image}
              nom={item.nom}
              note={item.note}
              message={item.message}
            />
           </LogoWrapper>
        ))}

     
      </Slider>
    </Container>
  );
}

const LogoWrapper = styled.div`
  width: 80%;
  padding: 0 5%;
  cursor: pointer;
  :focus-visible {
    outline: none;
    border: 0px;
  }
`;
