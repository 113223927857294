import {
  createStyles,
  Text,
  Container,
  Group,
  Image,
  Box,
  Grid,
} from "@mantine/core";
import {
  IconBrandTwitter,
  IconBrandInstagram,
  IconBrandFacebook,
  IconBrandLinkedin,
  IconBrandWhatsapp,
} from "@tabler/icons";
import { useNavigate } from "react-router";
import logo from "./../../assets/img/czion/element1/nav_white_icon.png";
import supportLogo from "../../assets/img/czion/element1/diddddv.png";
// import { Link } from "react-scroll";
import {Link } from 'react-router-dom'
const useStyles = createStyles((theme) => ({
  footer: {
    paddingTop: theme.spacing.xl * 2,
    paddingBottom: theme.spacing.xl * 2,
    backgroundColor: "#550096",
    color: "white",
    borderTop: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[5] : theme.colors.gray[2]
    }`,
  },

  logo: {
    // maxWidth: 150,

    [theme.fn.smallerThan(810)]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  },

  description: {
    [theme.fn.smallerThan(810)]: {
      marginTop: theme.spacing.xs,
      textAlign: "center",
    },
  },

  inner: {
    display: "flex",
    justifyContent: "space-around",

    [theme.fn.smallerThan(810)]: {
      flexDirection: "column",
      alignItems: "center",
    },
  },

  groups: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: 40,
    justifyContent: "space-around",

    // [theme.fn.smallerThan("sm")]: {
    //   display: "none",
    // },
  },

  // wrapper: {
  //   width: 200,
  // },

  link: {
    display: "block",
    color: "white",
    fontSize: theme.fontSizes.sm,
    paddingTop: 5,
    paddingBottom: 3,
    marginTop: 3,

    "&:hover": {
      textDecoration: "underline",
      color: "white",
      cursor:'pointer'
    },
  },

  title: {
    fontSize: theme.fontSizes.lg,
    fontWeight: 700,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    marginBottom: theme.spacing.xs / 2,
    color: "white",
    marginTop: 5,
  },

  afterFooter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: theme.spacing.xl,
    paddingTop: theme.spacing.xl,
    paddingBottom: theme.spacing.xl,
    borderTop: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[2]
    }`,

    [theme.fn.smallerThan(810)]: {
      flexDirection: "column",
    },
  },

  social: {
    cursor: "pointer",
    [theme.fn.smallerThan(810)]: {
      marginTop: theme.spacing.xs,
    },
  },
}));

const data = [
  {
    title: "Liens utiles ",
    links: [
      {
        icon: "",
        label: "Acceuil ",
        link: "/#home",
      },
      {
        icon: "",
        label: "Services",
        link: "/#services",
      },
      {
        icon: "",
        label: "A propos",
        link: "/about",
      },
      {
        icon: "",
        label: "Faqs",
        link: "/faq",
      },
      {
        icon: "",
        label: "Contact",
        link: "/contact",
      },
    ],
  },
  {
    title: "Compagnies",
    links: [
      {
        icon: "",
        label: "Mentions légales ",
        link: "/mention",
      },
      {
        icon: "",
        label: "Politique de cookies & confidentialité ",
        link: "/politique",
      },
      {
        icon: "",
        label: "Documentation",
        link: "/documentation",
      },
    ],
  },
  {
    title: "Contact",
    links: [
      {
        icon: "fa fa-envelope mr-2 mt-2",
        label: "info@czionfinance.com",
        link: "/mailto:info@czionfinance.com",
      },
      {
        icon: "fa fa-phone mr-2 mt-2",
        label: "+229 66730356",
        link: "#",
      },
      {
        icon: "fa fa-map-marker mr-2 mt-2",
        label: "Benin Oueme , Porto-Novo",
        link: "#",
      },

      {
        icon: "",
        label: "",
        link: "#",
      },
    ],
  },
];

export default function FooterLinks() {
  const getCurrentYear = () => {
    return new Date().getFullYear();
  };

 // const navigate = useNavigate();
  const { classes } = useStyles();
  const path = window.location.pathname;
  const groups = data.map((group) => {
    const links = group.links.map((link, index) => (
      <div className="flex">
        <i className={link.icon} aria-hidden="true"></i>
        <Link 
          key={index}
          className={classes.link}
          to={link.link}
         onClick= {() => {
            if(path ==="/")
            {
              if(link.link[1] === "#")
              {
                const element = document.getElementById(link.link.slice(2));
                console.log(element) ; 
                if (element) {
                  // 👇 Will scroll smoothly to the top of the next section
                  element.scrollIntoView({ behavior: 'smooth' });
                }
                if(link.link.substring(0, 4) === "mail")
                window.location.href= link.link
                
              }
            }else 
            {
              if(link.link.substring(0, 4) === "mail")
                window.location.href= "/"+link.link
            }
          }}

          
        >
          {link.label}
        </Link>
        {group.title === "Contact" && index === group.links.length - 1 && (
          <Group spacing={3} className={classes.social} position="right" noWrap>
            {/* <ActionIcon size="lg"> */}

            <IconBrandFacebook size={28} stroke={1.5} c={"white"}  onClick={ () => {
              window.location.href = "https://www.facebook.com/czionfinance"
            }}  />
            {/* </ActionIcon> */}
            {/* <ActionIcon size="lg"> */}
            <IconBrandInstagram size={28} stroke={1.5} onClick={ () =>{ 
               window.location.href='https://www.instagram.com/czionfinance'
            }} />

            {/* </ActionIcon> */}
            {/* <ActionIcon size="lg"> */}
            <IconBrandTwitter size={30} stroke={1.5} onClick={ () => {
              window.location.href = "https://twitter.com/czionfinance"
            }}/>
            {/* </ActionIcon> */}
            {/* <ActionIcon size="lg"> */}
            <IconBrandLinkedin size={28} style={{ color: "white" }} onClick={ () =>{
               window.location.href='https://www.linkedin.com/company/czion-group/'
            }} />

            <IconBrandWhatsapp size={28} style={{ color: "white" }} onClick={ () =>{
               window.location.href='https://wa.me/22966730356'
            }} />

            {/* </ActionIcon> */}
          </Group>
        )}
      </div>
    ));

    return (
      // <div className={classes.wrapper} key={group.title}>
      //   <Text className={classes.title}>{group.title}</Text>
      //   {links}
      // </div>
      <Grid.Col md={3}  xs={6} key={group.title}>
        <Text className={classes.title}>{group.title}</Text>
        {links}
      </Grid.Col>
    );
  });

  return (
    <footer className={classes.footer}>
      <Container size="xl">
        {/* <Box className={classes.inner}>
        <div className={classes.logo}>
          <Image src={logo} width={150} />
          <Text size="xs" color="white" className={classes.description}>
            La plateforme la plus évolutive pour echanger vos devises et
            cryptomonnaies en Afrique
          </Text>
          <Text my={30}>Support de paiement</Text>
          <div className="flex items-between">
            <img src={supportLogo} alt="" />
          </div>
        </div>
        <div className={classes.groups}>{groups}</div>
      </Box> */}
        <Box>
          <Grid>
            <Grid.Col md={4}>
              <div className={classes.logo}>
                <Image src={logo} width={150} />
                <Text size="xs" color="white">
                  La plateforme la plus évolutive pour echanger vos devises et
                  cryptomonnaies en Afrique
                </Text>
                <Text my={30}>Support de paiement</Text>
                <div className="flex items-between">
                  <img src={supportLogo} alt="" />
                </div>
              </div>
            </Grid.Col>
            <Grid.Col md={8}>
              <Container>
                {/* <Grid>
                {groups}
                </Grid> */}
                <div className={classes.groups}>{groups}</div>
              </Container>
            </Grid.Col>
          </Grid>
        </Box>

        <Box className={classes.afterFooter}>
          <Text
            color="white"
            size="sm"
            // className="  font-semibold flex justify-center"
          >
            Copyright © {getCurrentYear()} , All rights reserved. Powered by
            Czionfinance
          </Text>
        </Box>
      </Container>
    </footer>
  );
}
