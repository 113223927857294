import React from "react";
import {
  createStyles,
  Image,
  Accordion,
  Grid,
  Col,
  Container,
  Text,
  Box,
} from "@mantine/core";
import { IconPlus } from "@tabler/icons";
import politiqueImg from "./../../assets/img/cookies.svg";
import TopNavbar from "../Nav/TopNavbar";

const useStyles = createStyles((theme) => ({
  wrapper: {
    paddingTop: theme.spacing.xl * 2,
    paddingBottom: theme.spacing.xl * 2,
  },

  title: {
    marginBottom: theme.spacing.md,
    paddingLeft: theme.spacing.md,
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
  },

  item: {
    fontSize: theme.fontSizes.sm,
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[1]
        : theme.colors.gray[7],
  },

  BoxAccordion:{
    marginTop:100,
    [theme.fn.smallerThan("md")]: {
        marginTop:0,
      },
  }
}));

function Politiques(props) {
  const { classes } = useStyles();
  return (
    <div className={classes.wrapper}>
      <TopNavbar />

      <Container size="lg" mt={60} mb={100}>
        <div className="flex justify-center">
           <Text tt={"uppercase"} fz={25} fw={900}>
              Politiques de Cookies et Confidentialités
            </Text>
        </div>
     
        <Grid id="faq-grid" gutter={50}>
          <Col span={12} md={6}>
            
            <Image src={politiqueImg} alt="Mentions légales" mt={100} width={375}/>
          </Col>
          <Col span={12} md={6}>
            <Box className={classes.BoxAccordion}>
              <Accordion
                chevronPosition="right"
                defaultValue="who-are-us"
                variant="separated"
                chevron={<IconPlus size={30} fw={900} />}
                styles={{
                  chevron: {
                    "&[data-rotate]": {
                      transform: "rotate(45deg)",
                    },
                  },
                }}
              >
                {/* Liste des accordions */}

                <Accordion.Item className={classes.item} value={"1"}>
                  <Accordion.Control>
                    <Text fw={600} fz={"lg"} tt={"title"}>
                      1. Qu'est-ce que les Cookies ?
                    </Text>
                  </Accordion.Control>
                  <Accordion.Panel>
                    <Text fz={"md"}>
                      Comme la plupart des sites-web professionnels, ce site
                      utilise des cookies, qui sont de minuscules fichiers
                      téléchargés sur votre ordinateur, pour améliorer votre
                      expérience. Cette page décrit les informations
                      recueillies, comment nous les utilisons et pourquoi nous
                      avons parfois besoin de les stocker. Nous allons également
                      partager la façon dont vous pouvez empêcher ces cookies
                      d'être stocké, bien que cela puisse nuire à certaines
                      fonctionnalités du site. Pour plus d'informations
                      générales sur les cookies, consultez l'article Wikipedia
                      sur les cookies HTTP.
                    </Text>
                  </Accordion.Panel>
                </Accordion.Item>

                {/* Deuxième accordion */}
                <Accordion.Item className={classes.item} value={"2"}>
                  <Accordion.Control>
                    <Text fw={600} fz={"lg"} tt={"title"}>
                      2. Comment utilisons-nous les cookies ?
                    </Text>
                  </Accordion.Control>
                  <Accordion.Panel>
                    <Text fz={"md"}>
                      Nous utilisons des cookies pour diverses raisons
                      détaillées ci-dessous. Malheureusement, dans la plupart
                      des cas, il n'existe aucune option standard pour
                      désactiver les cookies sans désactiver complètement les
                      fonctionnalités et fonctionnalités ajoutées à ce site. Il
                      est recommandé de laisser tous les cookies si vous ne
                      savez pas si vous en avez besoin ou non dans le cas où ils
                      sont utilisés pour fournir un service que vous utilisez.
                    </Text>
                  </Accordion.Panel>
                </Accordion.Item>

                {/* Troisième accordion */}
                <Accordion.Item className={classes.item} value={"3"}>
                  <Accordion.Control>
                    <Text fw={600} fz={"lg"} tt={"title"}>
                      3. Désactivation des cookies
                    </Text>
                  </Accordion.Control>
                  <Accordion.Panel>
                    <Text fz={"md"}>
                      Vous pouvez empêcher le paramétrage des cookies en
                      ajustant les paramètres de votre navigateur (consultez
                      l'aide de votre navigateur pour savoir comment procéder).
                      Soyez conscient que la désactivation des cookies affectera
                      les fonctionnalités de ce site et de nombreux autres sites
                      que vous visitez. Il est donc recommandé de ne pas
                      désactiver les cookies.
                    </Text>
                  </Accordion.Panel>
                </Accordion.Item>

                {/* Quatrième accordion */}
                <Accordion.Item className={classes.item} value={"4"}>
                  <Accordion.Control>
                    <Text fw={600} fz={"lg"} tt={"title"}>
                      4. Cookies tiers
                    </Text>
                  </Accordion.Control>
                  <Accordion.Panel>
                    <Text fz={"md"}>
                      Dans certains cas particuliers, nous utilisons également
                      des cookies fournis par des tiers de confiance. La section
                      suivante détaille les cookies tiers que vous pourriez
                      rencontrer via ce site. Ce site utilise Google Analytics
                      qui est l'une des solutions d'analyse les plus répandues
                      et les plus fiables sur le Web pour nous aider à
                      comprendre comment vous utilisez le site et les façons
                      dont nous pouvons améliorer votre expérience. Ces cookies
                      peuvent suivre des choses telles que la durée de votre
                      séjour sur le site et les pages que vous visitez afin que
                      nous puissions continuer à produire du contenu attrayant.
                      Pour plus d'informations sur les cookies Google Analytics,
                      consultez la page Google Analytics officielle.
                    </Text>
                    <Text fz={"md"} mt={20}>
                      Nous utilisons également des boutons de médias sociaux et
                      des plugins sur ce site qui vous permettent de vous
                      connecter à votre réseau social de diverses façons. Afin
                      de permettre au site de se connecter à des plate-formes
                      telles que Twitter, ou Facebook, nous utilisons des
                      cookies qui peuvent être utilisés pour améliorer votre
                      profil sur leur site ou de contribuer aux données qu'ils
                      détiennent pour diverses fins énoncées dans leurs
                      politiques de confidentialité respectives.
                    </Text>
                  </Accordion.Panel>
                </Accordion.Item>

                {/* Cinquième accordion */}
                <Accordion.Item className={classes.item} value={"5"}>
                  <Accordion.Control>
                    <Text fw={600} fz={"lg"} tt={"title"}>
                      5. Plus d'informations
                    </Text>
                  </Accordion.Control>
                  <Accordion.Panel>
                    <Text fz={"md"}>
                      Nous espérons que cela a clarifié les choses pour vous et
                      comme cela a été mentionné précédemment si vous ne savez
                      pas si vous avez besoin ou non, il est généralement plus
                      sûr de laisser les cookies activés dans le cas où ils
                      interagissent avec l'une des Fonctionnalités que vous
                      utilisez sur notre site. Cependant, si vous désirez
                      obtenir davantage d'informations, vous pouvez nous
                      contacter:
                      <a
                        href="mailto:info@czionfinance.com"
                        style={{ color: "#5ca2dc" }}
                      >
                        info@czionfinance.com
                      </a>
                      .
                    </Text>
                  </Accordion.Panel>
                </Accordion.Item>
              </Accordion>
            </Box>
          </Col>
        </Grid>
      </Container>
    </div>
  );
}

export default Politiques;
