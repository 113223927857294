import React from "react";
import styled from "styled-components";
// Components
import TestimonialSlider from "../Elements/TestimonialSlider";
import {

  Card,
  Container,
  createStyles,
  Grid,
  Image,
  Text,
} from "@mantine/core";
import contrat from "../../assets/img/czion/element1/contrat.png";
import Encryption from "../../assets/img/czion/element1/Encryption.png";
import Sell from "../../assets/img/czion/element1/Sell.png";
import Verified from "../../assets/img/czion/element1/Verified.png";

import Vector from "../../assets/img/czion/element1/Vector.png";
import Slider from "react-slick";
const data = [
  {
    image: contrat,
    titre: "Transparence",
    description:
      "Nous mettons le maximum d'informations à votre disposition pour chacune de vos transactions avec des factures et le suivi instantannée",
  },
  {
    image: Sell,
    titre: "Fluide",
    description:
      "Echangez vos devises & cryptos avec nous de façon intuitive et fluide ! Echanger des devises ne vous parraitra plus compliqué & stressan",
  },
  {
    image: Encryption,
    titre: "Sécurité de mise",
    description:
      "Vous êtes propriétaire de vos fonds, aucun système d'attribution de fonds ne se fait. Nous échangeons en commun accord avec vous",
  },
  {
    image: Verified,
    titre: "Accessible sur tout support",
    description:
      "Que vous soyez sur PC, tablette, mobile, nous vous offrons les meilleurs services de change avec une fluiditié remarquable dans vos échanges",
  },
];

const useStyles = createStyles((theme) => ({
  hiddenDesktop: {
    [theme.fn.largerThan(810)]: {
      display: "none",
    },
  },
  hiddenMobile: {
    [theme.fn.smallerThan(810)]: {
      display: "none",
    },
  },
}));
export default function Blog() {
  const { classes } = useStyles();

  const settings = {
    dots: true,
    infinite: true,
    speed: 100,
    slidesToShow: 2,
    slidesToScroll: 2,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <Wrapper id="blog">
      <div className="gradient">
        <div className="container">
          <Grid>
            <Grid.Col md={5}>
              <Container c={"white"} py={100}>
                <HeaderInfo>
                  <Text>Pourquoi nous ?</Text>
                  <h1 className="font40 extraBold">
                    DES DOUTES ? NOUS LES <br />
                    DISSIPONS !
                  </h1>
                  <p className="font13">
                    
                    <br />
                    
                  </p>
                </HeaderInfo>
                <Image src={Vector} alt={"vgdhsj"} width={330}  className={classes.hiddenDesktop}/>
                <Image src={Vector} alt={"vgdhsj"} width={338}  className={classes.hiddenMobile}/>
              </Container>
            </Grid.Col>
            <Grid.Col md={7}>
              <Container py={50} className={classes.hiddenMobile}>
                <Grid>
                  {data.map((item, index) => (
                    <Grid.Col md={6}>
                      <Card
                        shadow="sm"
                        p="s"
                        radius="lg"
                        bg={index !== 1 ? "#3d3da7" : "none"}
                        c="white"
                        className="avecBordur"
                        withBorder={index !== 1 ? true : false}
                      >
                        <Card.Section my={10}>
                          <Image
                            src={item.image}
                            alt="texte"
                            width={50}
                            mx={"auto"}
                            my={30}
                          />
                        </Card.Section>
                        <Card.Section my={10} mx={10}>
                          <Text ta={"center"} my={30} fz={18} fw={500}>
                            {item.titre}
                          </Text>
                          <Text ta={"center"} my={30}>
                            {item.description}
                            <br />
                          </Text>
                        </Card.Section>
                      </Card>
                    </Grid.Col>
                  ))}
                </Grid>
              </Container>
              <Container py={50} className={classes.hiddenDesktop}>
                <Slider {...settings}>
                  {data.map((item, index) => (
                    <Card
                      shadow="sm"
                      p="s"
                      radius="lg"
                      bg={"#3d3da7"}
                      c="white"
                      className="avecBordur"
                      withBorder={true}
                    >
                      <Card.Section my={10}>
                        <Image
                          src={item.image}
                          alt="texte"
                          width={50}
                          mx={"auto"}
                          my={30}
                        />
                      </Card.Section>
                      <Card.Section my={10} mx={10}>
                        <Text ta={"center"} my={30} fz={18} fw={500}>
                          {item.titre}
                        </Text>
                        <Text ta={"center"} my={30}>
                          {item.description}
                          <br />
                        </Text>
                      </Card.Section>
                    </Card>
                  ))}
                </Slider>
              </Container>
            </Grid.Col>
          </Grid>
        </div>
      </div>

      {/* Section de temoignage */}
      {/* Niveau de problème */}
      <div className="" style={{ padding: "50px 0" }}>
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold textCenter">
              CE QUE DISENT NOS CLIENTS!{" "}
            </h1>
            <Text ta={"center"} fz={"xl"}>
              Ils nous ont choisi et partagent avec vous leur expérience 
            
            </Text>
          </HeaderInfo>
          <Container py={30} size={'xl'}>
            <TestimonialSlider />
          </Container>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding-top: 20px;
`;
const HeaderInfo = styled.div`
  margin-bottom: 30px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
