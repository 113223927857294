import React from "react";
import {
  createStyles,
  Image,
  Accordion,
  Grid,
  Col,
  Container,
  Text,
  Box,
  List,
  Badge,
  Code,
} from "@mantine/core";
import { IconPlus } from "@tabler/icons";
import documentImg from "./../../assets/img/documentations.svg";
import TopNavbar from "../Nav/TopNavbar";

const useStyles = createStyles((theme) => ({
  wrapper: {
    paddingTop: theme.spacing.lg * 2,
    paddingBottom: theme.spacing.lg * 2,
  },

  title: {
    marginBottom: theme.spacing.md,
    paddingLeft: theme.spacing.md,
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
  },

  item: {
    fontSize: theme.fontSizes.sm,
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[1]
        : theme.colors.gray[7],
  },
  BoxAccordion: {
    marginTop: 100,
    [theme.fn.smallerThan("md")]: {
      marginTop: 0,
    },
  },
}));

function Documentation(props) {
  const { classes } = useStyles();
  return (
    <div className={classes.wrapper}>
      <TopNavbar />

      <Container size="lg" mt={60} mb={100}>
        <div className="flex justify-center">
          <Text tt={"uppercase"} fz={25} fw={900}>
            Documentation
          </Text>
        </div>
        <Grid id="faq-grid" gutter={50}>
          <Col span={12} md={6}>
            <Image src={documentImg} alt="Mentions légales" mt={50} />
          </Col>
          <Col span={12} md={6}>
            <Box className={classes.BoxAccordion}>
              <Accordion
                chevronPosition="right"
                defaultValue="who-are-us"
                variant="separated"
                chevron={<IconPlus size={30} fw={600} />}
                styles={{
                  chevron: {
                    "&[data-rotate]": {
                      transform: "rotate(45deg)",
                    },
                  },
                }}
              >
                {/* Liste des accordions */}

                <Accordion.Item className={classes.item} value={"1"}>
                  <Accordion.Control>
                    <Text fw={600} fz={"lg"} tt={"title"}>
                      Introduction
                    </Text>
                  </Accordion.Control>
                  <Accordion.Panel>
                    <Text fz={"md"}>
                      Le Site internet Bienvenu sur la documentation officielle
                      de <strong>CZION FINANCE</strong>. Vous retrouverez ici,
                      toutes les informations utiles pour prendre en main
                      l'ensemble des fonctionnalités aussi bien au niveau
                      administrateur que client. Par la même occasion, vous
                      aurez toutes les cartes en main pour une utilisation plus
                      fluide et une meilleure expérience utilisateur.
                    </Text>
                    <Text fz="md">
                      <strong> Glossaires & lexiques généreaux</strong> <br />
                      <br /> Toutes les fonctionnalités préfixés pardésignent
                      celles exclusivement reservées aux administrateurs. <br />
                      <br /> Toutes les notions générales seront introduites au
                      début de chaque chapitre. Toute fois pour toute
                      préoccupation, nous sommes disponibles pour vous aider au
                      mieux.
                    </Text>
                  </Accordion.Panel>
                </Accordion.Item>

                {/* Deuxième accordion */}
                <Accordion.Item className={classes.item} value={"2"}>
                  <Accordion.Control>
                    <Text fw={600} fz={"lg"} tt={"title"}>
                      Know Your Users (KYC)
                    </Text>
                  </Accordion.Control>
                  <Accordion.Panel>
                    <Text fz={"md"}>
                      KYC (Know Your Customer) est le processus fondamental qui
                      définit et permet les relations entreprises-utilisateurs.
                      La procédure KYC est la première étape pour qu’une
                      personne devienne un client ou un utilisateur enregistré
                      d’une organisation ou d’une entreprise avec sécurité, en
                      toute garantie et dans le respect des réglementations qui
                      régissent ce fait.
                    </Text>
                    <Text fz={"md"} mt={15}>
                      Conformément aux lois en vigueur, tous nos utilisateurs
                      doivent passer leur vérification pour débloquer les
                      principales fonctionnalités de notre application. Une fois
                      votre demande de validation KYC soumis, nos
                      administrateurs se chargeront de vérifier l'authenticité
                      de vos documents.
                    </Text>
                    <Text fz={"md"} mt={15}>
                      Il existe trois états de validation de KYC dès que votre
                      demande est soumise:
                    </Text>
                    <List ml={30} spacing="xs" size="sm" center>
                      <List.Item>
                        <Badge color="orange">en attente</Badge> Dans cet état,
                        votre demande a bien été soumise et est en cours de
                        traitement par nos administrateurs
                      </List.Item>

                      <List.Item>
                        <Badge color="teal">validé</Badge>Dans cet état, votre
                        demande a bien été soumise et est acceptée par nos
                        administrateurs
                      </List.Item>

                      <List.Item>
                        <Badge color="red">rejeté</Badge>Dans cet état, votre
                        demande contient des irrégularités qui vous seront
                        communiqué par nos administrateurs
                      </List.Item>
                    </List>
                  </Accordion.Panel>
                </Accordion.Item>

                {/* Troisième accordion */}
                <Accordion.Item className={classes.item} value={"3"}>
                  <Accordion.Control>
                    <Text fw={600} fz={"lg"} tt={"title"}>
                      Monnaies (Tokens)
                    </Text>
                  </Accordion.Control>
                  <Accordion.Panel>
                    <Text fz={"md"}>
                      Les monnaies constituent les devises à échanger. Elles
                      sont des actifs d'échanges de deux natures distinctes.
                    </Text>
                    <Text fz={"md"} mt={15}>
                      Les devises : sont des monnaies d'ordres fiduciaires & de
                      moyens de transfer d'argent connues. Nous pouvons citer
                      entre autre le Franc CFA (xof), le $PAYEER, $PM ... La
                      nomanclature symbolique (symbole) des devises demeure
                      simple. Il suffit de reprendre le nom du moyen de paiement
                      en miniscule sans caractères spéciaux Exemple : payeer
                    </Text>
                    <Text fz={"md"} mt={15}>
                      Les cryptos : sont des monnaies basées sur des blockchain
                      & la cryptographie. Nous pouvons distinguer le Bitcoin,
                      l'USDT , le BUSD ... La nomanclature symbolique (symbole)
                      de ces derniers est spéciale. en effet, il y a la notion
                      de blockchain qui intervient. Il peut -exixter- une même
                      cryptomonnaie sur difféfentes blockchain comme erc20,
                      bep20, trc20... Pour cela, la nomanclature symbolique se
                      présente sous le format{" "}
                      <Code fz={"md"} fw={"bold"}>
                        symbole-du-token.symbole-de-la-blockchain
                      </Code>{" "}
                      (Exemple : usdt.bep20). Le caractère{" "}
                      <span style={{ fontWeight: "bolder", fontSize: "md" }}>
                        {" "}
                        {"<<"}. {">>"}
                      </span>{" "}
                      est un séparateur important
                    </Text>
                  </Accordion.Panel>
                </Accordion.Item>

                {/* Quatrième accordion */}
                <Accordion.Item className={classes.item} value={"4"}>
                  <Accordion.Control>
                    <Text fw={600} fz={"lg"} tt={"title"}>
                      Possibilités de changes
                    </Text>
                  </Accordion.Control>
                  <Accordion.Panel>
                    <Text fz={"md"}>
                      Il s'agit des différents types d'échanges possibles sur la
                      plateforme <strong> CZION FINANCE</strong>
                    </Text>
                    <Text fz={"md"} mt={15}>
                      <span style={{ textDecoration: "underline" }}>
                        Les échanges devises
                      </span>{" "}
                      <br /> <br />
                      Les échanges devises concernent les transactions dans
                      lesquelles interviennent que des monnaies fiduciaires (
                      devises ). Un exemple concret serait l'achat de $payeer
                      par paiement en Francs CFA (xof).
                    </Text>
                    <Text fz={"md"} mt={15}>
                      <span style={{ textDecoration: "underline" }}>
                        Les crypos
                      </span>{" "}
                      <br /> <br />
                      Les échanges en cryptomonnaies regroupent les transactions
                      dont les deux monnaies sont configurées comme de type
                      cryptos mais également toutes les possibilités de
                      transactions depuis une cryptomonnaie vers le Franc CFA
                      ET/OU du Franc CFA vers une cryptomonnaie.
                    </Text>
                  </Accordion.Panel>
                </Accordion.Item>

                {/* Cinquième accordion */}
                <Accordion.Item className={classes.item} value={"5"}>
                  <Accordion.Control>
                    <Text fw={600} fz={"lg"} tt={"title"}>
                      Echanges
                    </Text>
                  </Accordion.Control>
                  <Accordion.Panel>
                    <Text fz={"md"}>
                      Conformément aux lois en vigueur, tous nos utilisateurs
                      doivent passer leur vérification pour débloquer les
                      principales fonctionnalités de notre application. Une fois
                      votre demande de validation KYC soumis, nos
                      administrateurs se chargeront de vérifier l'authenticité
                      de vos documents.
                    </Text>

                    <Text fz={"md"} mt={15}>
                      Il existe quatre statuts de transactions :
                    </Text>
                    <List ml={30} spacing="xs" size="sm" center mt={15}>
                      <List.Item>
                        <Badge >initialisé</Badge> indique que
                        vous avez initié un échange de devise
                      </List.Item>
                      <List.Item>
                        <Badge color="orange">en attente</Badge> indique que
                        vous avez passé l'étape de paiement et que le service
                        d'échange prend le relai pour vous servir au plus vite
                      </List.Item>

                      <List.Item>
                        <Badge color="teal">validé</Badge>Le service d'échange a
                        confirmé votre échange et vous a déjà servi.
                      </List.Item>

                      <List.Item>
                        <Badge color="red">annulé</Badge>indique une erreur lors
                        du traitement de votre échange. Le service d'échange se
                        chargera de vous notifier les raisons de la defaillance
                        et vous aider au mieux
                      </List.Item>
                    </List>
                  </Accordion.Panel>
                </Accordion.Item>

                {/* sixième accordion */}
                <Accordion.Item className={classes.item} value={"6"}>
                  <Accordion.Control>
                    <Text fw={600} fz={"lg"} tt={"title"}>
                      Parrainages
                    </Text>
                  </Accordion.Control>
                  <Accordion.Panel>
                    <Text fz={"md"}>
                      Gagnez un revenu passif avec notre programme de
                      parrainage. Invitez vos amis à utiliser{" "}
                      <strong>CZION FINANCE</strong> et gagnez des bonus. En
                      vous associant à <strong>CZION FINANCE</strong> gagnez
                      jusqu'à <strong>40%</strong> des commisions de change de
                      vos affiliés
                    </Text>
                  </Accordion.Panel>
                </Accordion.Item>
              </Accordion>
            </Box>
          </Col>
        </Grid>
      </Container>
    </div>
  );
}

export default Documentation;
