import React from "react";
import styled from "styled-components";
import {
 
  Box,

} from "@mantine/core";

export default function HeaderMention () {

    return (
        <Box
      mt={100}
      py={10}
      style={{ backgroundColor: "#3b1c8c", color: "white" }}
    >
      <div className="flex justify-center">
        <HeaderInfo>
          <h1 className="font20 extraBold text-uppercase">Mentions Légales  </h1>
          <p className="font10">Acceuil - Mentions Légales</p>
        </HeaderInfo>
      </div>
    </Box>

    )

}

const HeaderInfo = styled.div`
  padding: 70px 0 30px 0;
  text-align: center;
  @media (max-width: 860px) {
    text-align: center;
  }
`;