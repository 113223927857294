import React, { useEffect } from "react";
// Sections
import TopNavbar from "../components/Nav/TopNavbar";
import Header from "../components/Sections/Header";
import Services from "../components/Sections/Services";
import Projects from "../components/Sections/Projects";
import Blog from "../components/Sections/Blog";
import Pricing from "../components/Sections/Pricing";
import Footer from "../components/Sections/Footer";
import { Checkbox, List, Text } from "@mantine/core";
import { openConfirmModal } from "@mantine/modals";
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconX } from "@tabler/icons";


export default function Landing() {
  const CookiesAccepter = () =>
  showNotification({
    title: 'Notification',
    message: "Merci d'avoir accepter nos cookies",
    color: 'green',
    icon:<IconCheck size={16} />
  });

  const CookiesRejete = () =>
  showNotification({
    title: 'Notification',
    message: "Les cookies n'ont pas été acceptés",
    color: 'red',
    icon:<IconX size={16} />
  });


  const openModal = () =>
    openConfirmModal({
      title: (
        <Text
          ta={"center"}
          fz={"xl"}
          fw={500}
          sx={{ justifyContent: "center", alignContent: "center" }}
        >
          Cookies
        </Text>
      ),
      size:'auto',
      centered: true,
      children: (
        <>
          <Text size="sm" my={8} c={"#291d6c"}>
            Nous utilisons des cookies pour offrir des fonctionnalités utiles et
            mesurer les performances afin d'améliorer votre expérience.
          </Text>
          <List ml={15}>
            <List.Item>
              <Text size="sm" my={8} c={"#291d6c"}>
                -En cliquant sur Accepter tout, vous acceptez l'utilisation de
                tous les cookies.
              </Text>
            </List.Item>
            <List.Item>
              <Text size="sm" my={3} c={"#291d6c"}>
                -En cliquant sur Accepter les catégories sélectionnées, vous
                acceptez uniquement les catégories que vous avez sélectionnées.
              </Text>
            </List.Item>
          </List>
          <Text size="sm" my={8} c={"#291d6c"}></Text>

          <Checkbox.Group
            my={5}
            defaultValue={["react"]}
            label="Vous trouverez de plus amples informations dans notre politique de
            confidentialit"
          >
            <Checkbox value="Ncessaire" label="Nécessaire" checked={true} color={'#291d6c'} />
            <Checkbox value="Performance" label="Performance" color={'#291d6c'} />
            <Checkbox value="Statistique" label="Statistique" color={'#291d6c'} />
          </Checkbox.Group>
        </>
      ),
      labels: { confirm: "Accepter Tous", cancel: "Accepter la selection" },
      confirmProps: { gradient:true, color: 'red',className:'link2',margin:'10' },
      cancelProps: { className:'refuser' },
      onCancel: () => CookiesAccepter(),
      onConfirm: () =>{
        window.localStorage.setItem('cookie','refuser');
        CookiesRejete()
      } ,
    });

  useEffect(() => {

    if(!window.localStorage.getItem("cookie")) openModal();
  }, []);

  return (
    <>
      <TopNavbar />
      <Header />
      <Services />
      <Projects />
      <Blog />
      <Pricing />
      <Footer />
    </>
  );
}
