import React from 'react';
import Team from '../Elements/Team';
import NotreVison from '../Elements/NotreVison';
import Mission from  '../Elements/Mission';
import { Box, createStyles } from '@mantine/core';

const useStyles = createStyles((theme) => ({
    hiddenDesktop: {
      [theme.fn.largerThan("md")]: {
        display: "none",
      },
    },
    ChangeColor: {
    backgroundColor:'#291d6c',
    borderRadius:'200px 20px',
      [theme.fn.smallerThan("md")]: {
        borderRadius:'0px',
      },
    },
  }));

function About(props) {

    const { classes } = useStyles();
    return (
        <Box >
            
            <Box className="mt-8" >
                 <NotreVison />
            </Box>

            <Box className={classes.ChangeColor} >
                <Mission/>
            </Box>
           
           <div className="mt-4">
             <Team />
           </div>
            
        </Box>

    );
}

export default About;