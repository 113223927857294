import { Text,Box } from '@mantine/core';
import React from 'react';
import EquipeCard from './EquipeCard';




function Team(props) {

    return (

    
        <Box >
            <Box className="my-4 justify-center flex">
                <Text fw={900} fz={35}>
                    Notre  équipe
                </Text>
            </Box>
            <Box className="mt-2 flex justify-center">
              <Text fw={500} fz={15} mx={20}>
                Le groupe dynmique qui vous supporte aux travers de ces services 
              </Text>
            </Box>
           <EquipeCard />
        </Box>
    );
}

export default Team;