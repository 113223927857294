import React from 'react';
import {
    Container,
    Text
  } from "@mantine/core";
  import styled from "styled-components";
  import target from "../../assets/img/czion/target.png"
function NotreVison(props) {

    return (
        <Container size="lg" mt={60} >
            <div className="justify-center flex">
              <Text fw={900} fz={35}>
                 Notre <span className='text-pink-700'>vision </span> 
                </Text>
           </div>
            <Advertising className="flexSpaceCente r">
            <AddLeft>
            <Container size={'lg'} className="container justify-center text-justify flex">
               
                <Text fz={"md"} className="mt-3">
                   Notre vision est de fournir une large gamme de solutions financières à la fois flexibles et sur mesure pour répondre à vos besoins spécifiques, des particuliers, des PME,  des entreprises internationales et  des acteurs publiques à travers un réseau financier révolutionnaire apportant l'accessibilité à de multiples initiatives dans l'espace numérique Ouest-africain avec des solutions et des services sans tracas qui apportent éfficacité et facilité d'utilisation dans les transactions traditionnellement complexes.   
                </Text>
                

            
            
            </Container>
            </AddLeft>

            <AddRight style={{ marginTop: "5px" }}>
            <AddRightInner>
                <div className="flexNullCenter mt-2">
                <img src={target} alt="" /> 
                </div>
            </AddRightInner>
            </AddRight>
           </Advertising>
        </Container>
       
    );

    
}

const Advertising = styled.div`
  margin: 15px 0;
  padding: 10px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 100px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 50px 0 0px 0;
  }
`;

const AddLeft = styled.div`
  width: 50%;
  position: absolute;
  top: 80px;
  right: 0;
  @media (max-width: 860px) {
    width: 100%;
    position: relative;
    order: 2;
    top: -40px;
   
  }
`;
const AddRight = styled.div`
  width: 50%;
  margin-bottom: 50px;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 1;
    text-align: center;
  }
`;
const AddRightInner = styled.div`
  width: 100%;
`;

export default NotreVison;