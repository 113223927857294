import { Avatar, Box, Card, Text } from "@mantine/core";
import React from "react";



export default function TestimonialBox({ image,nom,note,message }) {
  return (
    <Box style={{ border: "2px solid red", borderRadius: 12 }} px={20} my={60}>
      <Avatar
        src={image}
        mx={"auto"}
        radius={360}
        size={100}
        style={{
          position:'relative',
          marginTop: "-50px",
          zIndex: 99999999999,
          border: "2px solid red",
        }}
      />
      <Card.Section>
        <Text fz={"lg"} fw={700} ta={"center"} my={10}>
         {nom}
        </Text>
        <Text ta={"center"} mt={10} mb={50}>
         {message}
        </Text>
      </Card.Section>
    </Box>
  );
}
