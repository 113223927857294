import React from "react"
import styled from "styled-components";
// Components
import ClientSlider from "../Elements/ClientSlider";

import ServiceImage from "../../assets/img/czion/element1/serviceImage.svg";
import { StatsGroup } from "../Elements/SatetCard";
import av from "../../assets/img/czion/element1/av.png";
import changes from "../../assets/img/czion/element1/changes.png";
import transfert from "../../assets/img/czion/element1/transfert.png";
import financement from "../../assets/img/czion/element1/financement.png";
import servicebg from "../../assets/img/czion/bg/servicebg.svg";
import {
  Container,
  Image,
  Text,
  Box,
  Card,
  Grid,
  BackgroundImage,
} from "@mantine/core";
export default function Services() {
  const services = [
    {
      icon: av,
      title: "Achat & Vente",
      subtitle:
        "Nous mettons à votre disposition des moyens de paiements variées pour faciliter les échanges avec nous où que vous soyez",
    },
    {
      icon: changes,
      title: "Changes",
      subtitle:
        "Services de changes complets pour les particuliers, les petites et moyennes entreprises (PME) et les sociétés internationales",
    },

    {
      icon: transfert,
      title: "Transferts",
      subtitle:
        "Envoyez et recevez de l'argent partout dans le monde et sans frais ",
    },

    {
      icon: financement,
      title: "Financement",
      subtitle:
        "Nous vous offrons un hub d'échange fiable, efficace et adapté en Afrique, pour épargner et accéder au captital ",
    },
  ];
  return (
    <Wrapper id="services">
      <div className="whiteBg  mt-5" style={{ paddingY: "60px 0" }}>
        <div className="flex justify-center">
          <div className="mb-5 mt-12">
            <h1 className="font30 extraBold text-uppercase">
              {" "}
              NOS MOYENS DE PAIEMENT{" "}
            </h1>
          </div>
        </div>

        <Box className="flex justify-center " sx={{marginBottom:'-120px',borderRadius:40}}>
          <Box
            className="whiteBg lg:w-1/2  container hover:shadow-2xl focus:shadow-lg shadow-md duration-500 mb-4 flex-col items-center leading-none"
            style={{ align: "center", display: "flex",borderRadius:32 }}
          >
            <div className=" items-center ">
              <ClientSlider />
            </div>
          </Box>
        </Box>

        {/* Nos service section */}
        <div className=" radius8 rounded"  >
          <BackgroundImage src={servicebg}  style={{ borderRadius: "60px"}}>
            {/* backgroundColor:'#1c1c6c', */}
          <div className="container rounded-lg">
            <Box pt={170}>
              {/*  class="ServicesBg " */}
              <div>
                <HeaderInfo>
                  <Text ta={"center"} fw={900} fz={40} c={'white'}  mt={70}>
                    NOS SERVICES{" "}
                  </Text>
                  <Text ta={"center"} fz={20}   c={'white'}>
                    
                    <br />
                    
                  </Text>
                </HeaderInfo>
                <Container mt={100} pb={150} size={'lg'}>
                  <Grid >
                    {services.map((service, index) => (
                      <Grid.Col md={3}>
                        <Card
                          shadow="sm"
                          p="s"
                          radius="lg"
                          bg={index === 1 ? "#3d3da7" : "white"}
                          c="white"
                          className="avecBordur"
                          withBorder={index !== 1 ? true : false}
                          pb={index === 2 ? 44 : 1}
                        >
                          <Card.Section my={10}>
                            <Image
                              src={service.icon}
                              alt="texte"
                              width={50}
                              mx={"auto"}
                              my={30}
                            />
                          </Card.Section>
                          <Card.Section my={10} mx={10}>
                            <Text
                              className="font20 extraBold  "
                              ta={"center"}
                              my={30}
                              fz={18}
                              fw={500}
                              color={index !== 1 ? "#0B093B" : "white"}
                            >
                              {service.title}
                            </Text>
                            <Text
                              ta={"center"}
                              my={30}
                              className="font15"
                              color={index !== 1 ? "#0B093B" : "white"}
                            >
                              {service.subtitle}
                              <br />
                            </Text>
                          </Card.Section>
                        </Card>
                      </Grid.Col>
                    ))}
                  </Grid>
                </Container>
              </div>
            </Box>
          </div>
        </BackgroundImage>
        </div>
        


        <div className=" container rounded-lg ServicesBg ">
          <Advertising className="flexSpaceCente r">
            <AddLeft>
              <Container size={'lg'}>
                <Text fw={900} fz={35}>
                  #1 Plateforme pour échanger vos devises & cryptos
                </Text>
                <Text fz={"md"}>
                  Nous sommes une plateforme qui étudie vos besoins, les
                  comprend et qui prend le soin de développer des solutions
                  adéquates et idoines pour vous.
                </Text>

                <StatsGroup />
                <div className="my-8">
                    <a href="https://exchange.czionfinance.com/" class="py-2 px-6  font-semibold link hover:text-white"> E-changer avec nous &rarr; </a>
                </div>
              </Container>
            </AddLeft>

            <AddRight style={{ marginTop: "30px" }}>
              <AddRightInner>
                <div className="flexNullCenter mt-2">
                  <Image src={ServiceImage} alt="hdhfs" width={1000} />
                </div>
              </AddRightInner>
            </AddRight>
          </Advertising>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  background-color: #f5f5ff;
  @media (max-width: 860px) {
    margin-top: -100px;
  }


`;


const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;


const Advertising = styled.div`
  margin: 15px 0;
  padding: 10px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 100px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 50px 0 0px 0;
  }
`;

const AddLeft = styled.div`
  width: 50%;
  position: absolute;
  top: 70px;
  right: 0;
  @media (max-width: 860px) {
    width: 100%;
    position: relative;
    order: 1;
    top: -40px;
  }
`;
const AddRight = styled.div`
  width: 50%;
  margin-bottom: 50px;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRightInner = styled.div`
  width: 100%;
`;



