import React from "react";
import styled from "styled-components";
import { useEffect } from "react";
// Assets
import phone from "../../assets/img/czion/phone.png";
import location from "../../assets/img/czion/element1/location.png";
import mail from "../../assets/img/czion/element1/mail.png";
import {
  Box,
  Button,
  Card,
  Container,
  Grid,
  Image,
  Text,
  Textarea,
  TextInput,
} from "@mantine/core";
export default function Contact() {
  const path = window.location.pathname;
  useEffect(() => {
    // "document.documentElement.scrollTo" is the magic for React Router Dom v6
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant", // Optional if you want to skip the scrolling animation
    });
  }, path);

  const infos = [
    {
      icon: mail,
      title: " Email ",
      content: "info@czionfinance.com",
    },
    {
      icon: phone,
      title: "Téléphone",
      content: "+229 66730356",
    },
    {
      icon: location,
      title: "Adresse",
      content: "Benin, Oueme, Porto-Novo",
    },
  ];
  return (
    <Wrapper id="contact">
      <div className="lightBg flex items-center">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold text-uppercase">
              Formulaire de contact{" "}
            </h1>
            <p className="font13">
              Avez vous des préoccupations ? Souhaitez-vous discuter avec notre
              équipe ?
              <br />
              Ecrivez nous via ce formulaire, nous nous chargerons de vous
              répondre au plus vite
            </p>
          </HeaderInfo>

          <Container size={'lg'}>
            <Grid>
              {infos.map((item, index) => (
                <Grid.Col md={4}>
                  <Card
                    shadow="sm"
                    p="sm"
                    radius="lg"
                    // bg={index !== 1 ? "#3d3da7" : "none"}
                    c="black"
                    className="avecBordur"
                    withBorder={true}
                    sx={{ borderColor: "#1c23e0" }}
                  >
                    <Card.Section my={10}>
                      <Image
                        src={item.icon}
                        alt="texte"
                        width={50}
                        mx={"auto"}
                        my={30}
                      />
                    </Card.Section>
                    <Card.Section my={10} mx={10}>
                      <Text ta={"center"} my={30} fz={18} fw={500}>
                        {item.title}
                      </Text>
                      <Text ta={"center"} my={30}>
                        {item.content}
                        <br />
                      </Text>
                    </Card.Section>
                  </Card>
                </Grid.Col>
              ))}
            </Grid>
          </Container>

          <Container my={50}>
            <form style={{ alignContent: "center" }}>
              <Box>
                <Grid>
                  <Grid.Col md={6}>
                    {" "}
                    <TextInput
                      placeholder="Votre nom:"
                      radius={62}
                      size={'md'}
                      style={{ border: "1px solid #1c23e0", borderRadius: 62 }}
                    />
                  </Grid.Col>
                  <Grid.Col md={6}>
                    {" "}
                    <TextInput
                      placeholder="Email:"
                      type={"email"}
                      radius={62}
                      size={'md'}
                      style={{ border: "1px solid #1c23e0", borderRadius: 62 }}
                    />
                  </Grid.Col>
                  <Grid.Col md={6}>
                    <TextInput
                      placeholder="Nom de la société:"
                      type={"email"}
                      radius={62}
                      size={'md'}
                      style={{border:'1px solid #1c23e0',borderRadius:62}}
                    />
                  </Grid.Col>
                  <Grid.Col md={6}>
                    {" "}
                    <TextInput
                      placeholder="Téléphone:"
                      type={"email"}
                      radius={62}
                       size={'md'}
                      style={{border:'1px solid #1c23e0',borderRadius:62}}
                    />
                  </Grid.Col>

                  <Grid.Col>
                    <Textarea
                      placeholder="Parlez-nous de votre projet"
                      minRows={10}
                      radius={12}
                      style={{border:'1px solid #1c23e0',borderRadius:12}}
                    />
                  </Grid.Col>
                </Grid>
              </Box>

              <Box className="row flex justify-center">
                <Button
                  radius="xl"
                  size="sm"
                  my={50}
                  sx={{padding:'1px 50px'}}
                  variant="gradient"
                  gradient={{ from: "#bb2566", to: "#fb3c04", deg: 35 }}
                  
                >
                  Envoyer
                </Button>
              </Box>
            </form>
          </Container>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const HeaderInfo = styled.div`
  padding: 70px 0 30px 0;
  text-align: center;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
// const Form = styled.form`
//   padding: 70px 0 30px 0;

//   align-content: center;
//   input,
//   textarea {
//     width: 100%;
//     background-color: #ededfd;
//     border-radius: 15px;
//     padding: 12px 5px 0px;
//     box-shadow: none;
//     border: 1px solid #acaaff;
//     height: 40px;
//     margin-bottom: 30px;
//   }
//   textarea {
//     min-height: 100px;
//   }
//   @media (max-width: 860px) {
//     padding: 30px 0;
//   }
// `;

