import React, { useEffect } from "react";
import styled from "styled-components";
import {
  createStyles,
  Box,

} from "@mantine/core";
import Footer from "../components/Sections/Footer";
import TopNavbar from "../components/Nav/TopNavbar";
import Pricing from "../components/Sections/Pricing";
import About from "../components/Sections/About";


const useStyles = createStyles((theme) => ({
  wrapper: {
    paddingTop: theme.spacing.xl * 2,
    paddingBottom: theme.spacing.xl * 2,
  },

  title: {
    marginBottom: theme.spacing.md,
    paddingLeft: theme.spacing.md,
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
  },

  item: {
    fontSize: theme.fontSizes.sm,
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[1]
        : theme.colors.gray[7],
  },
  BoxAccordion: {
    marginTop: 150,
    [theme.fn.smallerThan("md")]: {
      marginTop: 0,
    },
  },
}));



function AboutScreen(props) {
  const { classes } = useStyles();
  const path = window.location.pathname;
  useEffect(() => {
    // "document.documentElement.scrollTo" is the magic for React Router Dom v6
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant", // Optional if you want to skip the scrolling animation
    });
  }, path);
  return (
    <div className={classes.wrapper} style={{backgroundColor:'#efeffd'}}>
      <TopNavbar />

      <Box
        mt={54}
        py={30}
        style={{ backgroundColor: "#3b1c8c", color: "white" }}
      >
        <div className="flex justify-center">
          <HeaderInfo>
            <h1 className="font20 extraBold text-uppercase">A propos  </h1>
            <p className="font10">Acceuil - A propos </p>
          </HeaderInfo>
        </div>
      </Box>
      <About />
      
      <Pricing />
      <Footer />
    </div>
  );
}

export default AboutScreen;

const HeaderInfo = styled.div`
  padding: 40px 0 20px 0;
  text-align: center;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
