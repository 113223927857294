import React from "react";
import styled from "styled-components";

export default function ProjectBox({ img, title, text, action }) {
  return (
    <Wrapper className="whiteBg">
      <img className="radius8" src={img} alt="project"></img>
      {/* <h3 className="font20 extraBold">{title}</h3> */}
      <p className="font13">{title}</p>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  border: 1px solid white;
  width: fit-content;
  margin: 30px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  gap: 10px;
  img {
    width: 22%;
    height: 10%;
    margin: 20px 0;
  }
  h3 {
    padding-bottom: 10px;
  }
`;
