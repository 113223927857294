import React from "react";
import styled from "styled-components";
// Components
import TopNavbar from "../Nav/TopNavbar.jsx";
// Assets
import herobg from "../../assets/img/czion/bg/herobg.svg";
import phone from "../../assets/img/czion/bg/phone.svg";

import {
  Box,
  createStyles,
  Group,
  Text,
} from "@mantine/core";

const useStyles = createStyles((theme) => ({
  bgImage: {
    [theme.fn.largerThan("md")]: {
      backgroundImage: herobg,
      backgroundSize: 50,
    },
    [theme.fn.smallerThan("md")]: {
      backgroundImage: 'url("../../assets/img/mobileBg.png")',
    },
  },
  hiroButton: {
    marginLeft: 27,
    [theme.fn.smallerThan("md")]: {
      display: "flex",
      justifyContent: "center",
      textAlign: "center",
      alignContent: "center",
    },
  },
  hiddenDesktop: {
    [theme.fn.largerThan("md")]: {
      display: "none",
    },
  },
  hiddenMobile: {
    [theme.fn.smallerThan("md")]: {
      display: "none",
    },
  },
  AvecMarge: {
    marginLeft: 60,
    [theme.fn.smallerThan("md")]: {
      marginLeft: 0,
    },
  },
}));
export default function Header() {
  const { classes } = useStyles();
  return (
    <Wrapper id="home">
      <Box sx={{ backgroundColor: "#f2f2ff" }} className={"bgImage"}>
      {/* <BackgroundImage src={mobileBg} radius="sm"> */}

      <Box className="container flexSpaceCenter headerStyle">
        <TopNavbar />
        <LeftSide className="flexCenter 25 ">
          <div>
            <Text
              fz={28}
              fw={800}
              c={"#1C1C6C"}
              mb={50}
              style={{ lineHeight: "50px", textShadow: "initial" }}
              className={`extraBold ${classes.AvecMarge} ${classes.hiddenMobile}`}
            >
              ACHETEZ, VENDEZ & ECHANGEZ  TOUTES VOS CRYPTOMONNAIES ICI{" "}
            </Text>
            <Text
              fz={20}
              fw={800}
              c={"#1C1C6C"}
              style={{ lineHeight: "30px", textShadow: "initial",marginTop:'-350px'}}
              className={`extraBold ${classes.AvecMarge} ${classes.hiddenDesktop} `}
            >
              ACHETEZ, VENDEZ & ECHANGEZ  TOUTES VOS CRYPTOMONNAIES ICI{" "}
            </Text>
            <Text fz={'md'} mt={10}  className={` ${classes.AvecMarge}`}>
              La plateforme la plus évolutive pour acheter <br/>et vendre des
              cryptomonnaies en Afrique
            </Text>

            <Group className={`${classes.hiroButton} ${classes.hiddenMobile}`}>
              <a
                href="https://exchange.czionfinance.com/"
                style={{
                  fontSize: "20px",
                  padding: "10px 35px",
                }}
                class="py-3 px-6  font-semibold link hover:text-white"
              >
                Connexion
              </a>

              <a
                href="https://exchange.czionfinance.com/public/signup"
                style={{
                  fontSize: "20px",
                  backgroundColor: "white",
                  color: "#1C1C6C",
                  borderRadius: "32px",
                  padding: "10px 35px",
                  marginRight: "40px",
                  border: "1px solid #1C1C6C",
                }}
              >
                Inscription
              </a>
            </Group>
            <Box mt={60} className={classes.hiddenDesktop} sx={{width:'100%'}}>
                  <a
                    href="https://exchange.czionfinance.com/"
                    style={{
                      fontSize: "20px",
                      padding: "10px 20px",
                    }}
                    class=" font-semibold link hover:text-white"
                  >
                    Connexion
                  </a>
               
               
                  <a
                    href="https://exchange.czionfinance.com/public/signup"
                    style={{
                      fontSize: "20px",
                      backgroundColor: "white",
                      color: "#1c1c6c",
                      borderRadius: "32px",
                      padding: "10px 20px",
                      border: "1px solid #1c1c6c",
                    }}
                  >
                    Inscription
                  </a>
               
            </Box>
          </div>
        </LeftSide>
        <RightSide>
          <ImageWrapper>
            {/* image telephone */}
            <Img
              className="radius8 phone"
              src={phone}
              alt="office"
              style={{ zIndex: 9 }}
            />
          </ImageWrapper>
        </RightSide>
      </Box>
      {/* </BackgroundImage> */}
      </Box>
    </Wrapper>
    
  );
}

const Wrapper = styled.section`
 
  width: 100%;
  min-height: 600px;
  height: 50vh;
  background-color: #f6f6fd;

  @media (max-width: 960px) {
    flex-direction: column;
    min-height: 540px;
    padding-bottom:-100px;
  }
`;
const LeftSide = styled.div`
  width: 50%;
  height: 100%;

  @media (max-width: 960px) {
    width: 100%;
    order: 2;
    margin: 50px 0;
    text-align: center;
  }
  @media (max-width: 560px) {
    margin: 80px 0 50px 0;
  }
`;
const RightSide = styled.div`
  width: 50%;
  height: 100%;
  @media (max-width: 960px) {
    display: none;
  }
`;



const ImageWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
  z-index: 9;
  @media (max-width: 960px) {
    width: 100%;
    justify-content: center;
  }
`;
const Img = styled.img`
  @media (max-width: 560px) {
    width: 80%;
    height: auto;
  }
`;

