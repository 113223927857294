import React from "react";
import { useEffect } from "react";
import TopNavbar from "../components/Nav/TopNavbar";
import HeaderMention from "../components/Sections/HeaderMention";
import Pricing from "../components/Sections/Pricing";
import Footer from  "../components/Sections/Footer"; 
import Mention from "../components/Sections/Mention";

export default function Mentions() {
    const path = window.location.pathname;
    useEffect(() => {
      // "document.documentElement.scrollTo" is the magic for React Router Dom v6
      document.documentElement.scrollTo({
        top: 0,
        left: 0,
        behavior: "instant", // Optional if you want to skip the scrolling animation
      });
    }, path);
    return (
        <>

        <TopNavbar />
        <HeaderMention/>
        <Mention />
        <Pricing />
        <Footer />
        
        </>
    )
}