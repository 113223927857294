import { Box, Text } from "@mantine/core";
import React from "react";
import Slider from "react-slick";

const dataEtapes = [
  {
    niveau: 1,
    titre: "Rejoignez notre grande",
    description:
      "Rejoignez notre grande communauté en vous inscrivant puis en confirmant votre inscription",
  },
  {
    niveau: 2,
    titre: "Configurez votre profil",
    description:
      "Soumettez votre demande de vérification comme utilisateur de la plateforme. En passant votre KYC, vous nous permettez de vous venir en aide",
  },
  {
    niveau: 3,
    titre: "Echangez avec nous",
    description:
      "Nous échangeons vos devises & cryptos à un tarif très compétitif sur le marché",
  },
];
const settings = {
  dots: true,
  infinite: true,
  autoplay: true,
  speed: 5000,
  autoplaySpeed: 5000,
  pauseOnHover: true,
  slidesToShow: 5,
  slidesToScroll: 2,
  arrows: false,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 400,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};
export function EtapesComponent(props) {
  return (
    <Slider {...settings}>
      {dataEtapes.map((element, index) => (
        <div>
          <div id="monStepper" style={{margin:'10px 0px'}}>
            <span className="boule shadow" style={{justifyContent:'center',textAlign:'center',alignContent:'center'}}>{element.niveau}</span>
          </div>
          <div>
            <Box my={15} sx={{width:'85%',marginInline:'auto'}}>
              <Box >
                <Text fz={"xl"} fw={500} ta={"center"}>
                  {element.titre}
                </Text>
                <Text ta={"justify"}>{element.description}</Text>
              </Box>
            </Box>
          </div>
        </div>
      ))}
    </Slider>
  );
}
